import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "200",
  height: "200",
  class: "icon",
  viewBox: "0 0 1024 1024"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#f6ef37",
      d: "M731.733 0H140.8C76.8 0 25.6 51.2 25.6 115.2v793.6c0 64 51.2 115.2 115.2 115.2h746.667c64 0 115.2-51.2 115.2-115.2V292.267L731.733 0",
      class: "selected",
      "data-spm-anchor-id": "a313x.search_index.0.i22.569f3a81ZKHpO9"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#2c2c2c",
      d: "M177.067 635.733V409.6h81.066c25.6 0 44.8 4.267 57.6 14.933 12.8 8.534 19.2 21.334 19.2 38.4 0 12.8-4.266 23.467-12.8 32-8.533 8.534-19.2 14.934-32 19.2 14.934 2.134 27.734 8.534 38.4 17.067 10.667 10.667 14.934 21.333 14.934 36.267 0 21.333-6.4 36.266-21.334 49.066-14.933 12.8-34.133 19.2-59.733 19.2h-85.333zm49.066-189.866v55.466H249.6c10.667 0 19.2-2.133 25.6-8.533s8.533-12.8 8.533-21.333c0-17.067-12.8-25.6-38.4-25.6h-19.2zm0 91.733v61.867h27.734c12.8 0 21.333-2.134 27.733-8.534 6.4-6.4 10.667-12.8 10.667-23.466 0-8.534-4.267-17.067-10.667-21.334-6.4-4.266-17.067-8.533-29.867-8.533h-25.6m136.534-10.667c0-36.266 10.666-64 32-87.466 21.333-21.334 49.066-34.134 83.2-34.134 32 0 59.733 10.667 78.933 32s29.867 49.067 29.867 83.2-10.667 64-29.867 85.334c-21.333 21.333-46.933 32-81.067 32-32 0-59.733-10.667-78.933-32-23.467-19.2-34.133-46.934-34.133-78.934zm51.2-4.266c0 21.333 6.4 40.533 17.066 53.333 10.667 14.933 25.6 21.333 44.8 21.333 19.2 0 34.134-6.4 44.8-19.2 10.667-12.8 14.934-32 14.934-53.333 0-23.467-4.267-42.667-14.934-55.467S494.933 448 477.867 448c-19.2 0-34.134 6.4-44.8 21.333s-19.2 32-19.2 53.334zM825.6 635.733V499.2c0-12.8 0-27.733 2.133-46.933H825.6c-2.133 14.933-4.267 23.466-6.4 29.866l-55.467 155.734h-38.4l-57.6-153.6c-2.133-4.267-4.266-12.8-6.4-29.867H659.2c2.133 19.2 2.133 38.4 2.133 57.6v128h-44.8V409.6h70.4L736 546.133c4.267 10.667 6.4 21.334 8.533 32 2.134-12.8 6.4-23.466 8.534-32L802.133 409.6H870.4v226.133h-44.8",
      "data-spm-anchor-id": "a313x.search_index.0.i21.569f3a81ZKHpO9"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#e0620d",
      d: "M731.733 0v194.133c0 53.334 44.8 98.134 100.267 98.134h170.667L731.733 0"
    }, null, -1)
  ])))
}
export default { render: render }