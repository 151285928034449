import { defineStore } from 'pinia';
import { Notification } from '@arco-design/web-vue';
import { useStorage } from '@vueuse/core';
import type { NotificationReturn } from '@arco-design/web-vue/es/notification/interface';
import type { RouteRecordNormalized } from 'vue-router';
import defaultSettings from '@/config/settings.json';
import { getMenuList } from '@/api/user';
import { AppState } from './types';

const menuCollapseFlag = useStorage(
  'cnsms-menu-collapse',
  defaultSettings.menuCollapse,
);

const defaultCollapseFlag = useStorage(
  'cnsms-menu-default-collapse',
  defaultSettings.defaultCollapse,
);

const topMenuFlag = useStorage('cnsms-menu-top', defaultSettings.topMenu);

const useAppStore = defineStore('app', {
  state: (): AppState => ({
    ...defaultSettings,
    // menuCollapse: menuCollapseFlag.value,
    defaultCollapse: defaultCollapseFlag.value,
    menuCollapse: defaultCollapseFlag.value ? true : menuCollapseFlag.value,
    topMenu: topMenuFlag.value,
    loading: false,
  }),

  getters: {
    appCurrentSetting(state: AppState): AppState {
      return { ...state };
    },
    appDevice(state: AppState) {
      return state.device;
    },
    appAsyncMenus(state: AppState): RouteRecordNormalized[] {
      return state.serverMenu as unknown as RouteRecordNormalized[];
    },
  },

  actions: {
    // Update app settings
    updateSettings(partial: Partial<AppState>) {
      if ('menuCollapse' in partial) {
        menuCollapseFlag.value = partial.menuCollapse;
      }
      if ('topMenu' in partial) {
        topMenuFlag.value = partial.topMenu;
      }
      if ('defaultCollapse' in partial) {
        defaultCollapseFlag.value = partial.defaultCollapse;
      }
      // @ts-ignore-next-line
      this.$patch(partial);
    },

    // Change theme color
    toggleTheme(dark: boolean) {
      if (dark) {
        this.theme = 'dark';
        document.body.setAttribute('arco-theme', 'dark');
        document.documentElement.classList.add('primevue-theme-dark');
      } else {
        this.theme = 'light';
        document.body.removeAttribute('arco-theme');
        document.documentElement.classList.remove('primevue-theme-dark');
      }
    },
    toggleDevice(device: string) {
      this.device = device;
    },
    toggleMenu(value: boolean) {
      this.hideMenu = value;
    },
    async fetchServerMenuConfig() {
      let notifyInstance: NotificationReturn | null = null;
      try {
        notifyInstance = Notification.info({
          id: 'menuNotice', // Keep the instance id the same
          content: 'loading',
          closable: true,
        });
        const { data } = await getMenuList();
        this.serverMenu = data;
        notifyInstance = Notification.success({
          id: 'menuNotice',
          content: 'success',
          closable: true,
        });
      } catch {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        notifyInstance = Notification.error({
          id: 'menuNotice',
          content: 'error',
          closable: true,
        });
      }
    },
    clearServerMenu() {
      this.serverMenu = [];
    },
    setFullLoading(loading = true) {
      this.loading = loading;
    },
  },
});

export default useAppStore;
