import axios from 'axios';
import type {
  ListRespNoPage,
  ListResp,
  ProductSeries,
  Pagination,
} from '@/types/store';
import type { SimpleStringKeyOption } from '@/types/global';

export type ProductSeriesParams = Partial<ProductSeries> & Partial<Pagination>;

export function getProductSeriesList(params?: ProductSeriesParams) {
  return axios.get<ListResp<ProductSeries>>(`/product_series.json`, {
    params,
  });
}

export function getProductSeries(productSeriesId: number | string) {
  return axios.get<ProductSeries>(`/product_series/${productSeriesId}.json`);
}

export function newProductSeries() {
  return axios.get<ProductSeries>(`/product_series/new.json`);
}

export function upsertProductSeries(data: ProductSeries) {
  if (data.id > 0) {
    return axios.put<ProductSeries>(`/product_series/${data.id}.json`, {
      data,
    });
  }
  return axios.post<ProductSeries>(`/product_series.json`, { data });
}

export function toggleProductSeries(
  productSeriesId: number | string,
  disabled: boolean,
) {
  return axios.post<ProductSeries>(
    `/product_series/${productSeriesId}/toggle.json`,
    {
      disabled,
    },
  );
}

export function getProductSeriesOptions() {
  return axios.get<ListRespNoPage<SimpleStringKeyOption>>(
    '/product_series/options.json',
  );
}
