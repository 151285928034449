import { computed } from 'vue';
// import { useI18n } from 'vue-i18n';
import i18n from '@/locale';
import { Message } from '@arco-design/web-vue';
import { enumNames } from '@/enums/biz';
import { setLocale } from '@/api/system';

export default function useLocale() {
  const i18 = i18n.global;
  const currentLocale = computed(() => {
    return i18.locale.value;
  });
  const changeLocale = async (value: string) => {
    if (i18.locale.value === value) {
      return;
    }
    i18.locale.value = value;
    localStorage.setItem('cnsms-locale', value);
    await setLocale(value);
    Message.success(i18.t('common.locale.switched'));
  };

  const getEnumLabel = (enumName: enumNames, val: string) => {
    if (!val) {
      return '';
    }
    return i18.t(`enums.${enumName}.${val}`);
  };

  return {
    currentLocale,
    changeLocale,
    getEnumLabel,
  };
}
