import axios from 'axios';
import type { AppConfig, ListRespNoPage, ProjectCategory } from '@/types/store';

export function getAppConfigs() {
  return axios.get<ListRespNoPage<AppConfig>>(`/app_configs.json`);
}

export function setAppConfig(key: string, value: number) {
  return axios.post(`/app_configs/set.json`, { data: { key, value } });
}

export function getNormalizedConfigs() {
  return axios.get<ListRespNoPage<AppConfig>>(`/app_configs/normalized.json`);
}

export function getProjectCategories() {
  return axios.get<ListRespNoPage<ProjectCategory>>(
    `/app_configs/project_categories.json`,
  );
}

export function updateProjectCategories(data: ProjectCategory[]) {
  return axios.post(`/app_configs/project_categories.json`, { data });
}
