<template>
  <section
    v-show="loading"
    class="full-loading"
    :class="{ absolute, [theme]: !!theme }"
    :style="[background ? `background-color: ${background}` : '']"
  >
    <a-spin
      v-bind="$attrs"
      :tip="tip"
      :size="size"
      :loading="loading"
      :dot="iconType === 'dot'"
    >
      <template v-if="iconType !== 'loading' && iconType !== 'dot'" #icon>
        <icon-sync v-if="iconType === 'sync'" />
        <icon-refresh v-if="iconType === 'refresh'" />
        <icon-clock-circle v-if="iconType === 'clock'" />
      </template>
    </a-spin>
  </section>
</template>

<script lang="ts">
  import { PropType, defineComponent } from 'vue';
  import {
    IconSync,
    IconRefresh,
    IconClockCircle,
  } from '@arco-design/web-vue/es/icon';

  export default defineComponent({
    name: 'Loading',
    components: {
      IconSync,
      IconRefresh,
      IconClockCircle,
    },
    props: {
      tip: {
        type: String as PropType<string>,
        default: '',
      },
      size: {
        type: Number,
        default: 20,
      },
      absolute: {
        type: Boolean as PropType<boolean>,
        default: false,
      },
      loading: {
        type: Boolean as PropType<boolean>,
        default: false,
      },
      background: {
        type: String as PropType<string>,
      },
      theme: {
        type: String as PropType<'dark' | 'light'>,
      },
      iconType: {
        type: String as PropType<
          'loading' | 'dot' | 'sync' | 'refresh' | 'clock'
        >,
        default: 'loading',
      },
    },
  });
</script>

<style lang="less" scoped>
  .full-loading {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 200;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.6);
    transition: opacity 0.1s cubic-bezier(0, 0, 1, 1);

    &.absolute {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 300;
    }
  }

  body[arco-theme='dark'] {
    .full-loading:not(.light) {
      background-color: rgba(51, 51, 51, 0.6);
    }
  }

  .full-loading.dark {
    background-color: rgba(51, 51, 51, 0.6);
  }
</style>
