import type { RouteRecordRaw } from 'vue-router';

import { REDIRECT_ROUTE_NAME } from '@/router/constants';

export const DEFAULT_LAYOUT = () => import('@/layout/default-layout.vue');

// 404 on a page
export const PAGE_404_ROUTE: RouteRecordRaw = {
  path: '/:pathMatch(.*)*',
  name: 'NotFoundRoot',
  component: DEFAULT_LAYOUT,
  meta: {
    locale: 'menu.exception.000',
    icon: 'icon-exclamation-circle',
    requiresAuth: true,
    hideInMenu: true,
  },
  children: [
    {
      path: '/:pathMatch(.*)*',
      name: 'NotFound',
      component: () => import('@/views/exception/404/index.vue'),
      meta: {
        locale: 'menu.exception.404',
        requiresAuth: true,
        permissions: ['*'],
      },
    },
  ],
};

export const REDIRECT_ROUTE: RouteRecordRaw = {
  path: '/redirect',
  component: DEFAULT_LAYOUT,
  name: 'RedirectTo',
  meta: {
    locale: 'menu.redirect',
    requiresAuth: false,
    hideInMenu: true,
  },
  children: [
    {
      path: '/redirect/:path(.*)',
      name: REDIRECT_ROUTE_NAME,
      component: () => import('@/views/redirect/index.vue'),
      meta: {
        locale: 'menu.redirect',
        requiresAuth: false,
        hideBreadcrumb: true,
      },
    },
  ],
};

export const EXCEPTIONS_ROUTE: RouteRecordRaw = {
  path: '/errors',
  name: 'ExceptionRoot',
  component: DEFAULT_LAYOUT,
  meta: {
    locale: 'menu.exception.000',
    icon: 'icon-exclamation-circle',
    requiresAuth: true,
    hideInMenu: true,
  },
  children: [
    {
      path: '/403',
      name: 'NoPermissionException',
      component: () => import('@/views/exception/403/index.vue'),
      meta: {
        locale: 'menu.exception.403',
        requiresAuth: true,
        permissions: ['*'],
      },
    },
    {
      path: '/500',
      name: 'ServerErrorException',
      component: () => import('@/views/exception/500/index.vue'),
      meta: {
        locale: 'menu.exception.500',
        requiresAuth: true,
        permissions: ['*'],
      },
    },
  ],
};
