import { h } from 'vue';
import { DEFAULT_LAYOUT } from '../base';
import IconQuote from '~icons/twemoji/shopping-cart';

export default {
  path: '/quotations',
  name: 'Quotation',
  component: DEFAULT_LAYOUT,
  meta: {
    locale: 'menu.quotation.rootMenu',
    requiresAuth: true,
    icon: () => h(IconQuote),
    order: 105,
    permissions: ['quote.use'],
  },
  children: [
    {
      name: 'QuotesListEntry',
      path: '',
      redirect: { name: 'QuotesList' },
      meta: {
        requiresAuth: true,
        permissions: ['quote.use'],
        hideInMenu: true,
      },
    },
    {
      path: '/quotes',
      name: 'QuotesList',
      component: () => import('@/views/quotes/my-quotes/index.vue'),
      meta: {
        locale: 'menu.quote.myQuotes',
        requiresAuth: true,
        // icon: 'icon-font color="#7c3aed" type="icon-4" :size="28"',
        permissions: ['quote.use'],
        hideInMenu: false,
      },
    },
    {
      path: '/quotes/:quoteId',
      name: 'QuoteDetail',
      component: () => import('@/views/quotes/quote-detail/index.vue'),
      meta: {
        locale: 'menu.quotation.quoteDetail',
        requiresAuth: true,
        permissions: ['quote.use'],
        hideInMenu: true,
        activeMenu: 'QuotesList',
      },
    },

    {
      path: '/quotes-mng',
      name: 'AllQuotesList',
      component: () => import('@/views/quotes/quotes-list/index.vue'),
      meta: {
        locale: 'menu.quote.quotesList',
        requiresAuth: true,
        permissions: [
          'quote.plan',
          'quote.director_approve',
          'quote.cfo_approve',
        ],
        hideInMenu: false,
      },
    },
    {
      path: '/quotes-mng/:quoteId',
      name: 'QuoteMngDetail',
      component: () => import('@/views/quotes/quote-detail/index.vue'),
      meta: {
        locale: 'menu.quotation.quoteDetail',
        requiresAuth: true,
        permissions: [
          'quote.plan',
          'quote.director_approve',
          'quote.cfo_approve',
        ],
        hideInMenu: true,
        activeMenu: 'AllQuotesList',
      },
    },

    {
      path: '/quote-items-list',
      name: 'AllQuoteItems',
      component: () => import('@/views/quotes/quote-items-list/index.vue'),
      meta: {
        locale: 'menu.quote.plannerQuoteItemsList',
        requiresAuth: true,
        permissions: ['quote.plan'],
        hideInMenu: false,
      },
    },

    // {
    //   path: '/app_configs',
    //   name: 'AppConfigManagement',
    //   component: () => import('@/views/system/app-config/index.vue'),
    //   meta: {
    //     locale: 'menu.quotation.appConfigManagement',
    //     requiresAuth: true,
    //     permissions: ['system.admin'],
    //   },
    // },
  ],
};
