import type { App } from 'vue';
import { createPinia } from 'pinia';
import useAppStore from './modules/app';
import useUserStore from './modules/user';
import useTagStore from './modules/tag';
import useCodesStore from './modules/codes';
import useTabBarStore from './modules/tab-bar';
import useSettingStore from './modules/setting';
import useHotlineTicketStore from './modules/hotline-ticket';

const store = createPinia();

export {
  useAppStore,
  useUserStore,
  useTabBarStore,
  useSettingStore,
  useTagStore,
  useCodesStore,
  useHotlineTicketStore,
};
export default store;

export function setupStore(app: App<Element>) {
  app.use(store);
}

export { store };

// For stores which might
export function useUserStoreWithOut() {
  return useUserStore(store);
}

// export function useAppStoreWithOut() {
//   return useAppStore(pinia);
// }
