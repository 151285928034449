import type { Router, LocationQueryRaw } from 'vue-router';
import NProgress from 'nprogress'; // progress bar

import usePermission from '@/hooks/permission';
import { logDebug, logError } from '@/utils/logger';
import { useUserStore } from '@/store';
import { PageEnum } from '@/enums/pageEnum';
import { LOGIN_NAME } from '@/router/constants';

// 白名单页面，无需检测，比如登录页面
const whitePathList: PageEnum[] = [PageEnum.BASE_LOGIN];

export default function setupPermissionGuard(router: Router) {
  router.beforeEach(async (to, from, next) => {
    NProgress.start();
    const userStore = useUserStore();
    async function crossroads() {
      const Permission = usePermission();
      if (Permission.accessRouter(to)) {
        next();
      } else {
        next({ name: 'NoPermissionException' });
      }
      NProgress.done();
    }

    // 在整个过程中，我们都假定用户是出于登录状态的，如果用户不是登录状态，axios的拦截器会自动跳转到登录页面
    // 除了白名单页面，白名单界面不需要去判断权限，所以可以直接跳转，但对于登录界面来说，如果用户已经登录，就跳转到首页或者上次访问的页面
    if (whitePathList.includes(to.path as PageEnum)) {
      // 如果当前是登录界面，且来源不是因为axios拦截器捕获的401错误，则尝试对用户进行登录操作，如果中间没有出错，则跳转用户到上次访问的页面
      if (to.path === PageEnum.BASE_LOGIN && to.query?.from !== 'logout') {
        try {
          // 先尝试获取用户状态，并尝试进行跳转，如果获取用户状态失败，拦截器会自动跳转
          await userStore.afterLoginAction();
          logDebug(
            '访问登录页面，获取用户信息成功，尝试跳转到登录后界面或者上次界面...',
          );
          next((to.query?.redirect as string) || PageEnum.BASE_HOME);
        } catch (error) {
          logError(error);
        }
      } else {
        next();
      }
      NProgress.done();
      return;
    }

    if (userStore.fetchedAt > 0 && userStore.permissions) {
      // 登录状态，且用户信息有效，并且权限信息存在
      crossroads();
    } else {
      // 登录状态，但用户信息还未获取，先获取
      try {
        await userStore.afterLoginAction();
        crossroads();
      } catch (error) {
        logError(error);
        await userStore.logout();
        next({
          name: LOGIN_NAME,
          query: {
            redirect: to.fullPath,
            from: 'logout',
          } as LocationQueryRaw,
        });
        NProgress.done();
      }
    }
  });
}
