import { h } from 'vue';
import { DEFAULT_LAYOUT } from '../base';
import IconLeads from '~icons/codicon/target';
import IconAllLeads from '~icons/entypo/flow-cascade';
import IconKanban from '~icons/emojione-monotone/delivery-truck';
import IconStockup from '~icons/vaadin/storage';
import IconSop from '~icons/fa-solid/warehouse';
import IconKa360 from '~icons/fa6-solid/street-view';
import IconLeadsDsahboard from '~icons/ph/chart-line-up';

export default {
  path: '/leads',
  name: 'LeadsEntry',
  component: DEFAULT_LAYOUT,
  meta: {
    locale: 'menu.leads.rootTitle',
    requiresAuth: true,
    icon: () => h(IconLeads),
    order: 10,
    permissions: ['*'],
  },
  children: [
    {
      path: 'dashboard',
      name: 'LeadsDashboard',
      component: () => import('@/views/leads/dashboard.vue'),
      meta: {
        locale: 'leads.labels.leadsDashboardTitle',
        requiresAuth: true,
        permissions: ['*'],
        hideInMenu: false,
        icon: () => h(IconLeadsDsahboard),
      },
    },

    {
      path: '',
      name: 'LeadsList',
      component: () => import('@/views/leads/index.vue'),
      meta: {
        locale: 'menu.leads.listTitle',
        requiresAuth: true,
        permissions: ['*'],
        hideInMenu: false,
        icon: () => h(IconAllLeads),
      },
    },
    {
      path: 'show/:leadId',
      name: 'LeadDetail',
      component: () => import('@/views/leads/lead-detail.vue'),
      meta: {
        locale: 'menu.leads.listTitle',
        requiresAuth: true,
        permissions: ['*'],
        hideInMenu: true,
        activeMenu: 'LeadsList',
      },
    },

    {
      path: 'supplychain-kanban',
      name: 'SupplychainKanban',
      component: () => import('@/views/leads/supplychain-kanban.vue'),
      meta: {
        locale: 'menu.leads.leadsSupplychainKanban',
        requiresAuth: true,
        permissions: ['lead.supplychain'],
        hideInMenu: false,
        icon: () => h(IconKanban),
      },
    },

    {
      path: 'supplychain-stockups',
      name: 'SupplychainStockups',
      component: () => import('@/views/leads/stockups.vue'),
      meta: {
        locale: 'menu.leads.leadsSupplychainStockups',
        requiresAuth: true,
        permissions: ['lead.stockup_plan'],
        hideInMenu: false,
        icon: () => h(IconStockup),
      },
    },

    {
      path: 'supplychain-delivery',
      name: 'SupplychainDeliveries',
      component: () => import('@/views/leads/delivery.vue'),
      meta: {
        locale: 'menu.leads.leadsSupplychainDelivieries',
        requiresAuth: true,
        permissions: ['lead.supplychain'],
        hideInMenu: false,
        icon: 'icon-calendar-clock',
      },
    },

    {
      path: 'sop',
      name: 'SopIndex',
      component: () => import('@/views/sop/index.vue'),
      meta: {
        locale: 'menu.sop.index',
        requiresAuth: true,
        permissions: ['sop.show'],
        hideInMenu: false,
        icon: () => h(IconSop),
      },
    },

    {
      path: 'ka360',
      name: 'Ka360Index',
      component: () => import('@/views/ka360/index.vue'),
      meta: {
        locale: 'menu.ka360.index',
        requiresAuth: true,
        permissions: ['account.view_ka360'],
        hideInMenu: false,
        icon: () => h(IconKa360),
      },
    },
    {
      path: 'ka360/:tag',
      name: 'ka360ViewWithTag',
      component: () => import('@/views/ka360/index.vue'),
      meta: {
        locale: 'menu.ka360.index',
        requiresAuth: true,
        permissions: ['account.view_ka360'],
        hideInMenu: true,
        activeMenu: 'Ka360Index',
      },
    },
  ],
};
