import { defineStore } from 'pinia';
import { getBrandOptions } from '@/api/brands';
import {
  getOptionsForOwnProduct,
  getOptionsForPurchasedProduct,
  getProductCategoriesTillModels,
} from '@/api/product-categories';
import { getProductTags, getClipTags } from '@/api/tag';
import {
  getPaymentTerms,
  getWarrantyTerms,
  getShippingMethod,
  getLovOptions,
} from '@/api/list-of-value';
import { getProductCustomConfigOptions } from '@/api/product-custom-configs';
import { getProductSeriesOptions } from '@/api/product-series';
import { getDrawingCategoryOptions } from '@/api/drawing-categories';
import { getProjectCategories } from '@/api/app-configs';
import { SimpleStringKeyOption } from '@/types/global';
import { getAllUsers } from '@/api/user';
import { getDepartments } from '@/api/department';
import { HotlineTicket } from '@/types/store';
import type { CodesState } from './types';

const useCodesStore = defineStore('codes', {
  state: (): CodesState => ({
    ownProductCategories: [],
    purchasedProductCategories: [],
    brands: [],
    paymentTerms: [],
    warrantyTerms: [],
    shippingMethod: [],
    leadsFailReasons: [],
    leadsPhases: [],
    productTags: [],
    clipTags: [],
    productCustomConfigs: [],
    allUsers: [],
    productSeries: [],
    drawingCategories: [],
    projectCategories: [],
    departments: [],
    productCategoriesTillModels: [],
  }),

  getters: {
    enabledUsers(state: CodesState) {
      return state.allUsers.filter((user) => !user.isDisabled);
    },

    selectableUsers(state: CodesState) {
      return state.allUsers.filter(
        (user) => !(user.listExcluded || user.isDisabled),
      );
    },

    selectableAllUsers(state: CodesState) {
      return state.allUsers.filter((user) => !user.listExcluded);
    },

    // 根据模块来获取的可在列表内显示的用户，不包含禁用用户，不包含列表排除用户（所有），不包含该模块内指定排除的用户
    usersForModule:
      (state: CodesState) =>
      (currentModule, withDisabled = false) => {
        return state.allUsers
          .filter(
            (u) =>
              (withDisabled || !u.isDisabled) &&
              !u.listExcluded &&
              !u.listExcludedModules?.includes(currentModule),
          )
          .sort((a, b) => a.alias.localeCompare(b.alias));
      },

    userById: (state: CodesState) => (userId) =>
      state.allUsers.find((u) => u.id === userId),

    usersWithRole: (state: CodesState) => (role: string) =>
      state.allUsers.filter(
        (u) =>
          !(u.listExcluded || u.isDisabled) &&
          u.roles &&
          u.roles.includes(role),
      ),

    candidatesForTicket: (state: CodesState) => (ticket: HotlineTicket) => {
      if (ticket.state === 'draft' && ticket.ticketType !== 'internal') {
        // 草稿状态提交时
        let roleName = ticket.ticketType;
        if (ticket.ticketType === 'presale') {
          roleName += ticket.newCustomer ? '_new_' : '_old_';
          roleName += ticket.region;
        }
        return state.allUsers
          .filter(
            (u) =>
              !u.isDisabled &&
              !u.listExcluded &&
              !u.listExcludedModules?.includes('HotlineTicket') &&
              u.ticketRoles.includes(roleName),
          )
          .sort((a, b) => a.alias.localeCompare(b.alias));
      }
      // 非草稿状态
      // 售前
      if (ticket.ticketType === 'presale') {
        return state.allUsers
          .filter(
            (u) =>
              !u.isDisabled &&
              !u.listExcluded &&
              !u.listExcludedModules?.includes('HotlineTicket') &&
              u.teamRoles.includes('sales'),
          )
          .sort((a, b) => a.alias.localeCompare(b.alias));
      }
      // 售后
      if (ticket.ticketType === 'aftersale') {
        return state.allUsers
          .filter(
            (u) =>
              !u.isDisabled &&
              !u.listExcluded &&
              !u.listExcludedModules?.includes('HotlineTicket') &&
              u.teamRoles.includes('service'),
          )
          .sort((a, b) => a.alias.localeCompare(b.alias));
      }
      // 投诉
      if (ticket.ticketType === 'complaint') {
        return state.allUsers
          .filter(
            (u) =>
              !u.isDisabled &&
              !u.listExcluded &&
              !u.listExcludedModules?.includes('HotlineTicket') &&
              (u.teamRoles.includes('sales') ||
                u.teamRoles.includes('service')),
          )
          .sort((a, b) => a.alias.localeCompare(b.alias));
      }
      // 广告，或者其它
      return state.allUsers
        .filter(
          (u) =>
            !u.isDisabled &&
            !u.listExcluded &&
            !u.listExcludedModules?.includes('HotlineTicket'),
        )
        .sort((a, b) => a.alias.localeCompare(b.alias));
    },

    paymentTermsRecord(state: CodesState) {
      const terms: Record<string, SimpleStringKeyOption> = {};
      state.paymentTerms.forEach((term) => {
        terms[term.value] = term;
      });
      return terms;
    },

    warrantyTermsRecord(state: CodesState) {
      const terms: Record<string, SimpleStringKeyOption> = {};
      state.warrantyTerms.forEach((term) => {
        terms[term.value] = term;
      });
      return terms;
    },

    shippingMethodRecord(state: CodesState) {
      const terms: Record<string, SimpleStringKeyOption> = {};
      state.shippingMethod.forEach((term) => {
        terms[term.value] = term;
      });
      return terms;
    },

    leadsFailReasonsRecord(state: CodesState) {
      const terms: Record<string, SimpleStringKeyOption> = {};
      state.leadsFailReasons.forEach((term) => {
        terms[term.value] = term;
      });
      return terms;
    },

    leadsPhasesRecord(state: CodesState) {
      const terms: Record<string, SimpleStringKeyOption> = {};
      state.leadsPhases.forEach((term) => {
        terms[term.value] = term;
      });
      return terms;
    },

    productCustomConfigRecord(state: CodesState) {
      const terms: Record<string, SimpleStringKeyOption> = {};
      state.productCustomConfigs.forEach((term) => {
        terms[term.value] = term;
      });
      return terms;
    },

    productSeriesByType(state: CodesState) {
      return (productType?: string) => {
        if (productType) {
          return state.productSeries.filter((series) =>
            series.tags?.includes(productType),
          );
        }
        return state.productSeries;
      };
    },

    productSeriesImported(state: CodesState) {
      return state.productSeries.filter((series) =>
        series.tags.includes('imported'),
      );
    },

    productSeriesLocal(state: CodesState) {
      return state.productSeries.filter((series) =>
        series.tags.includes('local'),
      );
    },

    projectCategoryRecords({ projectCategories: codes }) {
      const records: Record<string, string> = {};
      const loop = (list) => {
        list.forEach((code) => {
          records[code.key] = code.title;
          code.children && loop(code.children);
        });
      };
      loop(codes);
      return records;
    },

    projectCategoryRecordsEnu({ projectCategories: codes }) {
      const records: Record<string, string> = {};
      const loop = (list) => {
        list.forEach((code) => {
          records[code.key] = code.etitle;
          code.children && loop(code.children);
        });
      };
      loop(codes);
      return records;
    },

    // pass keys in an array, get the titles in an array from projectCategoryRecords
    projectCategoryNames() {
      return (category: string[], isChn = true) => {
        return (category || []).map((key) =>
          isChn
            ? this.projectCategoryRecords[key]
            : this.projectCategoryRecordsEnu[key],
        );
      };
    },
  },

  actions: {
    async loadBrands() {
      const {
        data: { list },
      } = await getBrandOptions();
      this.$patch((state) => {
        state.brands.splice(0, state.brands.length, ...list);
      });
    },

    async loadDepartments() {
      const {
        data: { list },
      } = await getDepartments();
      this.$patch((state) => {
        state.departments.splice(0, state.departments.length, ...list);
      });
    },

    async loadOwnProductCategories() {
      const {
        data: { list },
      } = await getOptionsForOwnProduct();
      this.$patch((state) => {
        state.ownProductCategories.splice(
          0,
          state.ownProductCategories.length,
          ...list,
        );
      });
    },

    async loadProductCategoriesTillModels() {
      const {
        data: { list },
      } = await getProductCategoriesTillModels();
      this.$patch((state) => {
        state.productCategoriesTillModels.splice(
          0,
          state.productCategoriesTillModels.length,
          ...list,
        );
      });
    },

    async loadPurchasedProductCategories() {
      const {
        data: { list },
      } = await getOptionsForPurchasedProduct();
      this.$patch((state) => {
        state.purchasedProductCategories.splice(
          0,
          state.purchasedProductCategories.length,
          ...list,
        );
      });
    },

    async loadPaymentTerms() {
      const {
        data: { list },
      } = await getPaymentTerms();
      this.$patch((state) => {
        state.paymentTerms.splice(0, state.paymentTerms.length, ...list);
      });
    },

    async loadWarrantyTerms() {
      const {
        data: { list },
      } = await getWarrantyTerms();
      this.$patch((state) => {
        state.warrantyTerms.splice(0, state.warrantyTerms.length, ...list);
      });
    },

    async loadShippingMethod() {
      const {
        data: { list },
      } = await getShippingMethod();
      this.$patch((state) => {
        state.shippingMethod.splice(0, state.shippingMethod.length, ...list);
      });
    },

    async loadProductTags() {
      const {
        data: { tags },
      } = await getProductTags();
      this.$patch((state) => {
        state.productTags.splice(0, state.productTags.length, ...tags);
      });
    },

    async loadClipTags() {
      const {
        data: { tags },
      } = await getClipTags();
      this.$patch((state) => {
        state.clipTags.splice(0, state.clipTags.length, ...tags);
      });
    },

    async loadProjectCategories() {
      const {
        data: { list },
      } = await getProjectCategories();
      this.$patch((state) => {
        state.projectCategories.splice(
          0,
          state.projectCategories.length,
          ...list,
        );
      });
    },

    async loadAllUsers() {
      const {
        data: { list },
      } = await getAllUsers();
      this.$patch((state) => {
        state.allUsers.splice(0, state.allUsers.length, ...list);
      });
    },

    async loadProductCustomConfigs() {
      const {
        data: { list },
      } = await getProductCustomConfigOptions();
      this.$patch((state) => {
        state.productCustomConfigs.splice(
          0,
          state.productCustomConfigs.length,
          ...list,
        );
      });
    },

    async loadProductSeries() {
      const {
        data: { list },
      } = await getProductSeriesOptions();
      this.$patch((state) => {
        state.productSeries.splice(0, state.productSeries.length, ...list);
      });
    },

    async loadDrawingCategories() {
      const {
        data: { list },
      } = await getDrawingCategoryOptions();
      this.$patch((state) => {
        state.drawingCategories.splice(
          0,
          state.drawingCategories.length,
          ...list,
        );
      });
    },

    async loadLovOptions(lovType: string, stateName: string) {
      const {
        data: { list },
      } = await getLovOptions(lovType);
      this.$patch((state) => {
        state[stateName].splice(0, state[stateName].length, ...list);
      });
    },

    async loadAllLovs() {
      await Promise.all([
        this.loadLovOptions('PAYMENT_TERMS', 'paymentTerms'),
        this.loadLovOptions('WARRANTY_TERMS', 'warrantyTerms'),
        this.loadLovOptions('SHIPPING_METHOD', 'shippingMethod'),
        this.loadLovOptions('LEADS_FAIL_REASON', 'leadsFailReasons'),
        this.loadLovOptions('LEADS_PHASE', 'leadsPhases'),
      ]);
    },
  },
});

export default useCodesStore;
