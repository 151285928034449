<template>
  <div v-if="!appStore.navbar" class="fixed-settings" @click="setVisible">
    <a-button type="primary">
      <template #icon>
        <icon-settings />
      </template>
    </a-button>
  </div>
  <a-drawer
    :width="500"
    unmount-on-close
    hide-cancel
    :visible="visible"
    @ok="cancel"
    @cancel="cancel"
  >
    <template #title>{{ $t('user.labels.userPreferences') }}</template>
    <Block
      :options="contentOpts"
      :title="$t('user.labels.userPreferencesLayout')"
    />
  </a-drawer>
</template>

<script lang="ts" setup>
  import { computed } from 'vue';
  // import { Message } from '@arco-design/web-vue';
  import { useI18n } from 'vue-i18n';
  // import { useClipboard } from '@vueuse/core';
  import { useAppStore } from '@/store';
  import Block from './block.vue';

  const emit = defineEmits(['cancel']);

  const appStore = useAppStore();
  const { t } = useI18n();
  // const { copy } = useClipboard();
  const visible = computed(() => appStore.globalSettings);
  const contentOpts = computed(() => [
    {
      name: 'settings.topMenu',
      key: 'topMenu',
      defaultVal: appStore.topMenu,
      type: 'radio',
      radioOptions: [
        { label: t('user.labels.userPreferencesMenuOnTop'), value: true },
        { label: t('user.labels.userPreferencesMenuOnLeft'), value: false },
      ],
    },
    {
      name: 'settings.defaultCollapse',
      key: 'defaultCollapse',
      defaultVal: appStore.defaultCollapse,
      type: 'radio',
      radioOptions: [
        { label: t('user.labels.userPreferencesMenuCollapse'), value: true },
        { label: t('user.labels.userPreferencesMenuLastState'), value: false },
      ],
    },
    // { name: 'settings.navbar', key: 'navbar', defaultVal: appStore.navbar },
    // {
    //   name: 'settings.menu',
    //   key: 'menu',
    //   defaultVal: appStore.menu,
    // },
    // { name: 'settings.footer', key: 'footer', defaultVal: appStore.footer },
    // { name: 'settings.tabBar', key: 'tabBar', defaultVal: appStore.tabBar },
    // {
    //   name: 'settings.menuFromServer',
    //   key: 'menuFromServer',
    //   defaultVal: appStore.menuFromServer,
    // },
    // {
    //   name: 'settings.menuWidth',
    //   key: 'menuWidth',
    //   defaultVal: appStore.menuWidth,
    //   type: 'number',
    // },
  ]);
  // const othersOpts = computed(() => [
  //   {
  //     name: 'settings.colorWeak',
  //     key: 'colorWeak',
  //     defaultVal: appStore.colorWeak,
  //   },
  // ]);

  const cancel = () => {
    appStore.updateSettings({ globalSettings: false });
    emit('cancel');
  };
  // const copySettings = async () => {
  //   const text = JSON.stringify(appStore.$state, null, 2);
  //   await copy(text);
  //   Message.success(t('settings.copySettings.message'));
  // };
  const setVisible = () => {
    appStore.updateSettings({ globalSettings: true });
  };
</script>

<style scoped lang="less">
  .fixed-settings {
    position: fixed;
    top: 280px;
    right: 0;

    svg {
      font-size: 18px;
      vertical-align: -4px;
    }
  }
</style>
