import axios from 'axios';
import type {
  ListResp,
  ListRespNoPage,
  ProductCategory,
  Pagination,
} from '@/types/store';
import type { SimpleTreeOption } from '@/types/global';

export type ProductCategoryParams = Partial<ProductCategory> &
  Partial<Pagination>;

export function getProductCategories(params?: ProductCategoryParams) {
  return axios.get<ListResp<ProductCategory>>('/product_categories.json', {
    params,
  });
}

export function getProductCategoriesList() {
  return axios.get<ListRespNoPage<ProductCategory>>(
    '/product_categories/list.json',
    {},
  );
}

export function getProductCategory(productCategoryId: number | string) {
  return axios.get<ProductCategory>(
    `/product_categories/${productCategoryId}.json`,
  );
}

export function upsertProductCategory(data: ProductCategory) {
  if (data.id > 0) {
    return axios.put<ProductCategory>(`/product_categories/${data.id}.json`, {
      data,
    });
  }
  return axios.post<ProductCategory>('/product_categories.json', { data });
}

export function toggleProductCategory(
  productCategoryId: number | string,
  disabled: boolean,
) {
  return axios.post<ProductCategory>(
    `/product_categories/${productCategoryId}/toggle.json`,
    {
      disabled,
    },
  );
}

export function getOptionsForOwnProduct() {
  return axios.get<ListRespNoPage<SimpleTreeOption>>(
    '/product_categories/own_options.json',
  );
}

export function getOptionsForPurchasedProduct() {
  return axios.get<ListRespNoPage<SimpleTreeOption>>(
    '/product_categories/purchased_options.json',
  );
}

export function getProductCategoriesTillModels() {
  return axios.get<ListRespNoPage<SimpleTreeOption>>(
    '/product_categories/tree_till_models.json',
  );
}
