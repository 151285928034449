import { defineStore } from 'pinia';
import * as Sentry from '@sentry/vue';
import { useCookies } from '@vueuse/integrations/useCookies';
import {
  passwordLogin,
  getUserInfo,
  LoginData,
  wxLogin,
  userLogout,
} from '@/api/user';
import { removeRouteListener } from '@/utils/route-listener';
import type { UserInfo } from '@/types/store';
import useSettingStore from '@/store/modules/setting';
import useCodesStore from '@/store/modules/codes';

const SESSION_TOKEN = 'CNSMS_WEB_LOGIN_FLAG';

interface UserState {
  userInfo: Nullable<UserInfo>;
  lastFetchedAt: number;
}

const useUserStore = defineStore('user', {
  state: (): UserState => ({
    userInfo: null,
    lastFetchedAt: 0,
  }),

  getters: {
    info(state: UserState): UserInfo {
      return state.userInfo;
    },

    fetchedAt(state: UserState): number {
      return state?.lastFetchedAt || 0;
    },

    permissions(state: UserState): Record<string, string[]> {
      return state.userInfo?.permissions;
    },

    userId(state: UserState): number {
      return state.userInfo?.id;
    },

    tokenExisted() {
      const cookies = useCookies([SESSION_TOKEN]);
      return !!cookies.get(SESSION_TOKEN);
    },

    alias(state: UserState): string {
      return state.userInfo?.alias;
    },

    firstName(): string {
      return this.alias?.split(' ')?.[0];
    },

    isAdmin(state: UserState): boolean {
      return !!state.userInfo?.isAdmin;
    },

    inImpersonateState(state: UserState): boolean {
      return !!state.userInfo?.inImpersonateState;
    },
  },

  actions: {
    // Set user's information
    setInfo(userInfo: Partial<UserInfo>) {
      this.$patch({ userInfo, lastFetchedAt: new Date().getTime() });
    },

    // Reset user's information
    resetInfo() {
      this.$reset();
    },

    clearToken() {
      const cookies = useCookies([SESSION_TOKEN]);
      cookies.remove(SESSION_TOKEN);
    },

    // Logout
    async logout(doRemoteLogout = false) {
      doRemoteLogout && (await userLogout());
      this.resetInfo();
      this.clearToken();
      removeRouteListener();
    },

    // 密码登录
    async login(loginForm: LoginData): Promise<UserInfo | null> {
      await passwordLogin(loginForm);
      return this.afterLoginAction();
    },

    // 企业微信登录
    async loginByWechat(code: string): Promise<UserInfo | null> {
      await wxLogin(code);
      return this.afterLoginAction();
    },

    // 用户登录以后需要做的操作，从服务端获取用户信息，从服务端获取应用配置信息和代码表信息
    async afterLoginAction(): Promise<UserInfo | null> {
      const userInfo = await this.getUserInfoAction();
      this.loadUserCodesAction();
      await this.setSessionToken();
      Sentry.setUser({
        email: userInfo?.email,
        id: userInfo?.id,
        username: userInfo?.alias,
      });
      return userInfo;
    },

    async setSessionToken() {
      const cookies = useCookies([SESSION_TOKEN]);
      cookies.set(SESSION_TOKEN, true);
    },

    // 从服务端获取用户信息，并更新store
    async getUserInfoAction(): Promise<UserInfo | null> {
      const { data } = await getUserInfo();
      this.setInfo(data);
      return data;
    },

    // 从服务端获取应用配置和代码表信息，并更新store
    async loadUserCodesAction(): Promise<void> {
      // Refresh settings store
      const settingStore = useSettingStore();
      const codesStore = useCodesStore();
      // codesStore.loadOwnProductCategories();
      // codesStore.loadPurchasedProductCategories();
      // codesStore.loadPaymentTerms();
      // codesStore.loadWarrantyTerms();
      // codesStore.loadShippingMethod();
      await Promise.all([
        settingStore.refreshAppConfigs(),
        codesStore.loadBrands(),
        codesStore.loadProductTags(),
        codesStore.loadAllUsers(),
        codesStore.loadAllLovs(),
        codesStore.loadProjectCategories(),
        codesStore.loadDepartments(),
        codesStore.loadProductCategoriesTillModels(),
        codesStore.loadDrawingCategories(),
      ]);
    },
  },
});

export default useUserStore;
