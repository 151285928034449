import { h } from 'vue';
import { DEFAULT_LAYOUT } from '../base';
import IconTickets from '~icons/f7/tickets';
import IconTicketAll from '~icons/carbon/calls-all';
import IconTicketAftersales from '~icons/ri/customer-service-2-line';
import IconTicketPresales from '~icons/line-md/phone-call-loop';
import IconTicketAb from '~icons/fluent-emoji-high-contrast/ab-button-blood-type';
import IconTicketIt from '~icons/mdi/computer-classic';
import IconTicketOthers from '~icons/material-symbols-light/other-admission-outline';
import IconTicketInternal from '~icons/octicon/tasklist-24';
import IconTicketFavorites from '~icons/uim/favorite';
import IconMine from '~icons/tdesign/user-setting';
import IconTicketDashboard from '~icons/tabler/home-stats';

export default {
  path: '/hotline-ticket',
  name: 'HotlineTicketsEntry',
  component: DEFAULT_LAYOUT,
  meta: {
    locale: 'menu.hotlineTicket.rootTitle',
    requiresAuth: true,
    icon: () => h(IconTickets),
    order: 10,
    permissions: ['hotline_ticket.show'],
  },
  children: [
    {
      path: 'dashboard',
      name: 'TicketsDashboard',
      component: () => import('@/views/hotline-ticket/dashboard.vue'),
      meta: {
        locale: 'hotlineTickets.labels.ticketDashboardTitle',
        requiresAuth: true,
        permissions: ['hotline_ticket.show'],
        hideInMenu: false,
        icon: () => h(IconTicketDashboard),
      },
    },

    {
      path: 't/all',
      name: 'TicketsList',
      props: { tab: 'all' },
      component: () => import('@/views/hotline-ticket/index.vue'),
      meta: {
        locale: 'hotlineTicket.labels.listEntryTitle',
        requiresAuth: true,
        permissions: ['hotline_ticket.show'],
        hideInMenu: false,
        icon: () => h(IconTicketAll),
      },
    },
    {
      path: 't/mine',
      name: 'TicketsListMine',
      component: () => import('@/views/hotline-ticket/index.vue'),
      props: { tab: 'mine' },
      meta: {
        locale: 'hotlineTicket.labels.tabs.mine',
        requiresAuth: true,
        permissions: ['hotline_ticket.show'],
        hideInMenu: false,
        icon: () => h(IconMine),
      },
    },
    {
      path: 't/internal',
      name: 'TicketsListInternal',
      component: () => import('@/views/hotline-ticket/index.vue'),
      props: { tab: 'internal' },
      meta: {
        locale: 'hotlineTicket.labels.tabs.internal',
        requiresAuth: true,
        permissions: ['hotline_ticket.show'],
        hideInMenu: false,
        icon: () => h(IconTicketInternal),
      },
    },
    {
      path: 't/presale',
      name: 'TicketsListPresale',
      component: () => import('@/views/hotline-ticket/index.vue'),
      props: { tab: 'presale' },
      meta: {
        locale: 'hotlineTicket.labels.tabs.presale',
        requiresAuth: true,
        permissions: ['hotline_ticket.show'],
        hideInMenu: false,
        icon: () => h(IconTicketPresales),
      },
    },
    {
      path: 't/aftersale',
      name: 'TicketsListAftersale',
      component: () => import('@/views/hotline-ticket/index.vue'),
      props: { tab: 'aftersale' },
      meta: {
        locale: 'hotlineTicket.labels.tabs.aftersale',
        requiresAuth: true,
        permissions: ['hotline_ticket.show'],
        hideInMenu: false,
        icon: () => h(IconTicketAftersales),
      },
    },
    {
      path: 't/ab',
      name: 'TicketsListAb',
      component: () => import('@/views/hotline-ticket/index.vue'),
      props: { tab: 'ab' },
      meta: {
        locale: 'hotlineTicket.labels.tabs.ab',
        requiresAuth: true,
        permissions: ['hotline_ticket.show'],
        hideInMenu: false,
        icon: () => h(IconTicketAb),
      },
    },
    {
      path: 't/it',
      name: 'TicketsListIt',
      component: () => import('@/views/hotline-ticket/index.vue'),
      props: { tab: 'it' },
      meta: {
        locale: 'hotlineTicket.labels.tabs.it',
        requiresAuth: true,
        permissions: ['hotline_ticket.show'],
        hideInMenu: false,
        icon: () => h(IconTicketIt),
      },
    },
    {
      path: 't/favorites',
      name: 'TicketsListFavorites',
      component: () => import('@/views/hotline-ticket/index.vue'),
      props: { tab: 'favorites' },
      meta: {
        locale: 'hotlineTicket.labels.tabs.favorites',
        requiresAuth: true,
        permissions: ['hotline_ticket.show'],
        hideInMenu: false,
        icon: () => h(IconTicketFavorites),
      },
    },
    {
      path: 't/others',
      name: 'TicketsListOthers',
      component: () => import('@/views/hotline-ticket/index.vue'),
      props: { tab: 'others' },
      meta: {
        locale: 'hotlineTicket.labels.tabs.others',
        requiresAuth: true,
        permissions: ['hotline_ticket.show'],
        hideInMenu: false,
        icon: () => h(IconTicketOthers),
      },
    },

    {
      path: 'show/:ticketId',
      name: 'TicketDetail',
      component: () => import('@/views/hotline-ticket/ticket-detail.vue'),
      meta: {
        // locale: 'hotlineTicket.labels.tabs.all',
        requiresAuth: true,
        permissions: ['hotline_ticket.show'],
        hideInMenu: true,
        activeMenu: 'TicketsList',
      },
    },
  ],
};
