import type { Directive, App } from 'vue';
import { createLoading } from '@/components/loading';

const loadingDirective: Directive = {
  mounted(el, binding) {
    const tip = el.getAttribute('loading-tip');
    const background = el.getAttribute('loading-background');
    const size = el.getAttribute('loading-size');
    const iconType = el.getAttribute('loading-icon');
    const fullscreen = !!binding.modifiers.fullscreen;
    const loadingProps = {
      tip,
      background,
      size: size || NaN,
      loading: !!binding.value,
      absolute: !fullscreen,
      iconType: iconType || 'loading',
    };
    if (!Number.isNaN(Number(size))) {
      loadingProps.size = Number(size);
    }
    const instance = createLoading(
      loadingProps,
      fullscreen ? document.body : el,
    );
    el.instance = instance;
  },
  updated(el, binding) {
    const { instance } = el;
    if (!instance) return;
    instance.setTip(el.getAttribute('loading-tip'));
    if (binding.oldValue !== binding.value) {
      instance.setLoading?.(binding.value && !instance.loading);
    }
  },
  unmounted(el) {
    el?.instance?.close();
  },
};

export function setupLoadingDirective(app: App) {
  app.directive('loading', loadingDirective);
}

export default loadingDirective;
