import dayjsLib from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import isBetween from 'dayjs/plugin/isBetween';
import 'dayjs/locale/zh-cn';
import { ShortcutType } from '@arco-design/web-vue';
import NP from 'number-precision';
import i18n from '@/locale';

const i18 = i18n.global;

dayjsLib.extend(isToday);
dayjsLib.extend(isSameOrAfter);
dayjsLib.extend(isBetween);
dayjsLib.extend(quarterOfYear);

export function smartDatetime(dateString: string): string {
  if (!dateString) {
    return '';
  }
  dayjsLib.extend(isToday);
  const timestamp = dayjsLib(dateString, 'YYYY-MM-DD HH:mm');
  if (timestamp.isToday()) {
    return timestamp.format('HH:mm');
  }
  return timestamp.format('MM-DD');
}

export function getDate(dateString: string): string {
  const timestamp = dayjsLib(dateString, 'YYYY-MM-DD HH:mm');
  return timestamp.format('MM-DD');
}

export const dateUtil = dayjsLib;

export function getCurrentTimestamp(): string {
  return dayjsLib().format('YYYY-MM-DD HH:mm:ss');
}

export function getToday(): string {
  return dayjsLib().format('YYYY-MM-DD');
}

export function getDefaultRangeShortcuts(): ShortcutType[] {
  return [
    {
      label: i18.t('common.labels.lastWeek'),
      value: () => [
        dateUtil().subtract(1, 'week').startOf('week').format('YYYY-MM-DD'),
        dateUtil().subtract(1, 'week').endOf('week').format('YYYY-MM-DD'),
      ],
    },
    {
      label: i18.t('common.labels.lastMonth'),
      value: () => [
        dateUtil().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
        dateUtil().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'),
      ],
    },
    {
      label: i18.t('common.labels.last30Days'),
      value: () => [
        dateUtil().subtract(30, 'day').format('YYYY-MM-DD'),
        dateUtil().format('YYYY-MM-DD'),
      ],
    },
    {
      label: i18.t('common.labels.thisMonth'),
      value: () => [
        dateUtil().startOf('month').format('YYYY-MM-DD'),
        dateUtil().format('YYYY-MM-DD'),
      ],
    },
    {
      label: i18.t('common.labels.thisQuarter'),
      value: () => [
        dateUtil().startOf('quarter').format('YYYY-MM-DD'),
        dateUtil().format('YYYY-MM-DD'),
      ],
    },
    {
      label: i18.t('common.labels.lastQuarter'),
      value: () => [
        dateUtil()
          .subtract(1, 'quarter')
          .startOf('quarter')
          .format('YYYY-MM-DD'),
        dateUtil().subtract(1, 'quarter').endOf('quarter').format('YYYY-MM-DD'),
      ],
    },
    {
      label: i18.t('common.labels.thisYear'),
      value: () => [
        dateUtil().startOf('year').format('YYYY-MM-DD'),
        dateUtil().format('YYYY-MM-DD'),
      ],
    },
    {
      label: i18.t('common.labels.lastYear'),
      value: () => [
        dateUtil().subtract(1, 'year').startOf('year').format('YYYY-MM-DD'),
        dateUtil().subtract(1, 'year').endOf('year').format('YYYY-MM-DD'),
      ],
    },
  ];
}

export function getFutureRangeShortcuts(): ShortcutType[] {
  return [
    {
      label: i18.t('common.labels.thisMonth'),
      value: () => [
        dateUtil().startOf('month').format('YYYY-MM-DD'),
        dateUtil().format('YYYY-MM-DD'),
      ],
    },
    {
      label: i18.t('common.labels.nextMonth'),
      value: () => [
        dateUtil().add(1, 'month').startOf('month').format('YYYY-MM-DD'),
        dateUtil().add(1, 'month').endOf('month').format('YYYY-MM-DD'),
      ],
    },
    {
      label: i18.t('common.labels.thisYear'),
      value: () => [
        dateUtil().startOf('year').format('YYYY-MM-DD'),
        dateUtil().endOf('year').format('YYYY-MM-DD'),
      ],
    },
  ];
}

export function getFutureMonthRangeShortcuts(): ShortcutType[] {
  return [
    {
      label: i18.t('common.labels.thisMonth'),
      value: () => [
        dateUtil().startOf('month').format('YYYY-MM-DD'),
        dateUtil().format('YYYY-MM-DD'),
      ],
    },
    {
      label: i18.t('common.labels.nextMonth'),
      value: () => [
        dateUtil().add(1, 'month').startOf('month').format('YYYY-MM-DD'),
        dateUtil().add(1, 'month').endOf('month').format('YYYY-MM-DD'),
      ],
    },
    {
      label: i18.t('common.labels.thisYear'),
      value: () => [
        dateUtil().startOf('year').format('YYYY-MM-DD'),
        dateUtil().endOf('year').format('YYYY-MM-DD'),
      ],
    },
    {
      label: i18.t('common.labels.todayToYearEnd'),
      value: () => [
        dateUtil().format('YYYY-MM-DD'),
        dateUtil().endOf('year').format('YYYY-MM-DD'),
      ],
    },
    {
      label: i18.t('common.labels.inFuture6Months'),
      value: () => [
        dateUtil().format('YYYY-MM-DD'),
        dateUtil().add(6, 'month').format('YYYY-MM-DD'),
      ],
    },
    {
      label: i18.t('common.labels.inFuture12Months'),
      value: () => [
        dateUtil().format('YYYY-MM-DD'),
        dateUtil().add(12, 'month').format('YYYY-MM-DD'),
      ],
    },
  ];
}

export function getPastMonthRangeShortcuts(): ShortcutType[] {
  return [
    {
      label: i18.t('common.labels.thisMonth'),
      value: () => [
        dateUtil().startOf('month').format('YYYY-MM-DD'),
        dateUtil().format('YYYY-MM-DD'),
      ],
    },
    {
      label: i18.t('common.labels.lastMonth'),
      value: () => [
        dateUtil().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
        dateUtil().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'),
      ],
    },
    {
      label: i18.t('common.labels.lastThreeMonths'),
      value: () => [
        dateUtil().startOf('month').format('YYYY-MM-DD'),
        dateUtil().subtract(3, 'month').endOf('month').format('YYYY-MM-DD'),
      ],
    },
    {
      label: i18.t('common.labels.thisQuarter'),
      value: () => [
        dateUtil().startOf('quarter').format('YYYY-MM-DD'),
        dateUtil().format('YYYY-MM-DD'),
      ],
    },
    {
      label: i18.t('common.labels.lastQuarter'),
      value: () => [
        dateUtil()
          .subtract(1, 'quarter')
          .startOf('quarter')
          .format('YYYY-MM-DD'),
        dateUtil().subtract(1, 'quarter').endOf('quarter').format('YYYY-MM-DD'),
      ],
    },
    {
      label: i18.t('common.labels.thisYear'),
      value: () => [
        dateUtil().startOf('year').format('YYYY-MM-DD'),
        dateUtil().format('YYYY-MM-DD'),
      ],
    },
    {
      label: i18.t('common.labels.lastYear'),
      value: () => [
        dateUtil().subtract(1, 'year').startOf('year').format('YYYY-MM-DD'),
        dateUtil().subtract(1, 'year').endOf('year').format('YYYY-MM-DD'),
      ],
    },
  ];
}

export function getPastDateRangeShortcuts(): ShortcutType[] {
  const monthShortcuts = getPastMonthRangeShortcuts();
  return [
    {
      label: i18.t('common.labels.thisWeek'),
      value: () => [
        dateUtil().startOf('week').format('YYYY-MM-DD'),
        dateUtil().format('YYYY-MM-DD'),
      ],
    },
    {
      label: i18.t('common.labels.lastWeek'),
      value: () => [
        dateUtil().subtract(1, 'week').startOf('week').format('YYYY-MM-DD'),
        dateUtil().subtract(1, 'week').endOf('week').format('YYYY-MM-DD'),
      ],
    },
    ...monthShortcuts,
  ];
}

export function wrapDuration(duration: number) {
  if (duration === null || duration === undefined) {
    return '';
  }
  return `${NP.round(NP.divide(duration || 0, 60), 1)} 小时/ ${NP.round(
    NP.divide(duration || 0, 480),
    1,
  )} 天`;
}

export function isBeforeCurrentMinute(minuteToCheck: number | string) {
  // Use dayjs to check if the passed in timestamp is earlier than the current time or not, truncate to minutes
  const currentTime = dayjsLib().startOf('minute');
  const timestamp = dayjsLib(minuteToCheck).startOf('minute');
  return timestamp.isBefore(currentTime);
}
