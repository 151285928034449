import { App, defineAsyncComponent } from 'vue';

export function registerGlobalComponents(app: App) {
  // Register quickview components that need async loading to avoid circular dependencies
  // https://git.novitqa.com:10443/novi/nlims-docs/-/issues/1040
  const components = {
    LeadsQuickView: () => import('@/views/leads/components/LeadsQuickView.vue'),
  };

  Object.entries(components).forEach(([name, component]) => {
    app.component(name, defineAsyncComponent(component));
  });
}
