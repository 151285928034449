import axios from 'axios';

export function clearViewCaches() {
  return axios.post(`/systems/clear_view_caches.json`);
}

export function setLocale(locale: string) {
  return axios.post(`/systems/set_locale.json`, {
    locale: locale === 'zh-CN' ? 'zh-CN' : 'en',
  });
}
