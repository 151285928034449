import { h } from 'vue';
import { DEFAULT_LAYOUT } from '../base';
import IconSalesOrder from '~icons/la/file-invoice-dollar';
import IconHistoryOrder from '~icons/mdi/invoice-text-clock-outline';
import IconReceivedPayment from '~icons/icon-park-outline/income';
import IconAccountManager from '~icons/mdi/text-account';
import IconRules from '~icons/ic/outline-rule';
import IconDelivery from '~icons/mdi/truck-delivery-outline';
import IconDeliverImage from '~icons/mdi/folder-table-outline';

export default {
  path: '/sales-orders',
  name: 'SalesOrder',
  component: DEFAULT_LAYOUT,
  meta: {
    locale: 'menu.salesOrder.rootMenu',
    requiresAuth: true,
    icon: 'icon-trophy',
    order: 10,
    permissions: [
      'sales_order.show',
      'received_payment.show',
      'sales_order.deliver',
      'sales_order.view_deliver',
      'delivery_image.show',
      'delivery_image.edit',
    ],
  },
  children: [
    {
      path: '',
      name: 'SalesOrdersList',
      component: () =>
        import('@/views/sales-orders/sales-orders-list/index.vue'),
      meta: {
        locale: 'menu.salesOrder.listTitle',
        requiresAuth: true,
        permissions: ['sales_order.show'],
        hideInMenu: false,
        icon: () => h(IconSalesOrder),
      },
    },
    {
      path: 'show/:salesOrderId',
      name: 'SalesOrderDetail',
      component: () =>
        import('@/views/sales-orders/sales-order-detail/index.vue'),
      meta: {
        locale: 'menu.salesOrder.listTitle',
        requiresAuth: true,
        permissions: ['sales_order.show'],
        hideInMenu: true,
        activeMenu: 'SalesOrdersList',
      },
    },

    {
      path: '/received-payments',
      name: 'ReceivedPaymentsList',
      component: () =>
        import('@/views/received-payments/received-payments-list/index.vue'),
      meta: {
        locale: 'menu.receivedPayment.listTitle',
        requiresAuth: true,
        permissions: ['received_payment.show'],
        hideInMenu: false,
        icon: () => h(IconReceivedPayment),
      },
    },

    {
      path: '/customers',
      name: 'CustomerManagement',
      component: () => import('@/views/system/customer-management/index.vue'),
      meta: {
        locale: 'menu.system.customerManagement',
        requiresAuth: true,
        permissions: ['customer.manage'],
        icon: () => h(IconAccountManager),
      },
    },

    {
      path: '/history-orders',
      name: 'HistoryOrdersList',
      component: () =>
        import('@/views/history-orders/history-orders-list/index.vue'),
      meta: {
        locale: 'menu.historyOrder.rootMenu',
        requiresAuth: true,
        permissions: ['system.admin'],
        hideInMenu: false,
        icon: () => h(IconHistoryOrder),
      },
    },
    {
      path: '/history-orders/:historyOrderId',
      name: 'HistoryOrderDetail',
      component: () =>
        import('@/views/history-orders/history-order-detail/index.vue'),
      meta: {
        locale: 'menu.historyOrder.rootMenu',
        requiresAuth: true,
        permissions: ['system.admin'],
        hideInMenu: true,
        activeMenu: 'HistoryOrdersList',
      },
    },

    {
      path: '/order_delivery',
      name: 'SalesOrderDeliveryIndex',
      component: () => import('@/views/sales-order-delivery/index.vue'),
      meta: {
        locale: 'menu.salesOrder.produceAndDelivery',
        requiresAuth: true,
        permissions: ['sales_order.deliver', 'sales_order.view_deliver'],
        icon: () => h(IconDelivery),
      },
    },

    {
      path: '/delivery_image',
      name: 'DeliveryImageIndex',
      component: () => import('@/views/delivery-image/index.vue'),
      meta: {
        locale: 'menu.salesOrder.deliveryImagesLib',
        requiresAuth: true,
        permissions: ['delivery_image.show', 'delivery_image.edit'],
        icon: () => h(IconDeliverImage),
      },
    },

    {
      path: '/contract_rules',
      name: 'ContractRuleIndex',
      component: () => import('@/views/contract-rules/index.vue'),
      meta: {
        locale: 'menu.contractRule.indexTitle',
        requiresAuth: true,
        permissions: ['contract_rule.manage'],
        icon: () => h(IconRules),
      },
    },
  ],
};
