import { h } from 'vue';
import axios, { InternalAxiosRequestConfig, AxiosResponse } from 'axios';
import { Notification, Modal } from '@arco-design/web-vue';
import { logDebug } from '@/utils/logger';
import { logout } from '@/utils/auth';
import { useUserStoreWithOut } from '@/store';
import router from '@/router';
// import { LOGIN_NAME } from '@/router/constants';

axios.defaults.headers.common['Content-Type'] =
  'application/json;charset=UTF-8';
axios.defaults.headers.common.Accept = 'application/json';
if (import.meta.env.VITE_API_BASE_URL) {
  axios.defaults.baseURL = import.meta.env.VITE_API_BASE_URL;
}
axios.defaults.withCredentials = true;

axios.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    // Using cookie instead of JWT token now
    // const token = getToken();
    // if (token) {
    //   config.headers.Authorization = `Bearer ${token}`;
    // }
    config.headers.set('X-App-Version', import.meta.env.VITE_WEB_VERSION);
    config.paramsSerializer = {
      indexes: false,
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// add response interceptors
axios.interceptors.response.use(
  (response: AxiosResponse<any>) => {
    const res = response.data;
    // if the custom code is not 20000, it is judged as an error.
    if (res.code !== 20000) {
      Notification.error({
        title: '后端服务处理错误 / Backend Process Error',
        content: res.msg || 'Error',
        duration: 0,
        closable: true,
      });
      // 50008: Invalid token
      // 50009: No user for wechat user id, or user was disabled
      // 50010: Invalid wechat callback info
      // 50011: No token provided
      // && response.config.url !== '/api/user/info'
      if ([50008, 50009, 50010, 50011].includes(res.code)) {
        Modal.error({
          title: `${res.code}: Invalid Session/无效的会话`,
          content: () =>
            h('p', null, [
              h('p', null, 'You have been logged out, please login again.'),
              h('p', null, '您已经被登出，请重新登录。'),
            ]),
          okText: 'Re-Login',
          async onOk() {
            const userStore = useUserStoreWithOut();
            await userStore.logout();
            logout({ redirect: router.currentRoute.value.fullPath });
          },
        });
      } // 如果是50012，表示客户端版本太旧，需要强制更新
      if (res.code === 50012) {
        Modal.error({
          title: `${res.code}: 客户端版本太旧/Client too old`,
          content: () =>
            h('p', null, [
              h('p', null, '您的客户端版本太旧，请刷新以更新。'),
              h(
                'p',
                null,
                'Your webapp client is too old, please refresh to update.',
              ),
            ]),
          okText: '刷新/Refresh',
          async onOk() {
            window.location.reload();
          },
        });
      }
      return Promise.reject(new Error(res.msg || 'Error'));
    }
    return {
      ...response,
      data: res.biz,
    };
  },
  async (error) => {
    const errorOpts = {
      title: '后端服务接口调用错误 / API Request Error',
      content: '请求服务器错误',
      duration: 0,
      closable: true,
    };

    try {
      if (error.response) {
        // The request was made and the server responded with a status code
        // Try to retreive msg from server response
        if (error.response.status === 408 || error.code === 'ECONNABORTED') {
          errorOpts.content += '，请求超时，请重试';
          Notification.error(errorOpts);
        } else if (error.response.status === 401) {
          // 如果是401错误，说明当前用户登录无效，需要引导用户到登录界面
          errorOpts.content +=
            'Unauthorized access, please login/无权限访问，请重新登录';
          logDebug('Unauthorized access: ', error.request);
          const userStore = useUserStoreWithOut();
          if (userStore.tokenExisted) {
            logDebug('Token existed, prompt user to re-login');
            Notification.error(errorOpts);
          }
          await userStore.logout();
          logout({ redirect: router.currentRoute.value.fullPath });
        } else if (error.response.data && error.response.data.msg) {
          const { msg } = error.response.data;
          errorOpts.content = msg;
        }
        Notification.error(errorOpts);
        logDebug('Response with error data: ', error.response.data);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        errorOpts.content += '，服务端处理超时或者无返回';
        Notification.error(errorOpts);
        logDebug('No response received with req: ', error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        errorOpts.content += `，无法构建请求，${error.message}`;
        Notification.error(errorOpts);
        logDebug('Request setup error with msg: ', error.message);
      }
    } catch (e) {
      errorOpts.content += `，无法构建解析错误，${e}`;
      Notification.error(errorOpts);
      logDebug('Error while parsing reponse error: ', e);
    }

    // errorOpts.content += `，配置错误，${error.config}`;
    logDebug('Request error with config: ', error.config);
    // logger.error(error.response);

    return Promise.reject(error);
  },
);
