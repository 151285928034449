import { I18nOptions } from 'vue-i18n';

const defaultSettings = {
  decimal02: {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    useGrouping: true,
  },
  decimal0: {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    useGrouping: true,
  },
  decimal2: {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    useGrouping: true,
  },
  decimal4: {
    style: 'decimal',
    minimumFractionDigits: 4,
    maximumFractionDigits: 4,
    useGrouping: true,
  },
  weight: {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    useGrouping: true,
  },
  percent100: {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    useGrouping: false,
  },
  percent: {
    style: 'percent',
    useGrouping: false,
  },
  percent1: {
    style: 'percent',
    useGrouping: false,
    maximumFractionDigits: 1,
  },
  percent3: {
    style: 'percent',
    useGrouping: false,
    maximumFractionDigits: 3,
  },
  currencyCNY: {
    style: 'currency',
    currency: 'CNY',
    useGrouping: true,
    currencyDisplay: 'symbol',
    maximumFractionDigits: 0,
  },
  currency2CNY: {
    style: 'currency',
    currency: 'CNY',
    useGrouping: true,
    currencyDisplay: 'symbol',
    maximumFractionDigits: 2,
  },
  currencyEUR: {
    style: 'currency',
    currency: 'EUR',
    notation: 'standard',
    maximumFractionDigits: 0,
  },
  currencyUSD: {
    style: 'currency',
    currency: 'USD',
    notation: 'standard',
    maximumFractionDigits: 0,
  },
  currency2EUR: {
    style: 'currency',
    currency: 'EUR',
    notation: 'standard',
    maximumFractionDigits: 2,
  },
  currency2USD: {
    style: 'currency',
    currency: 'USD',
    notation: 'standard',
    maximumFractionDigits: 2,
  },
};

const numberSettings = {
  'en-US': {
    ...defaultSettings,
  },
  'zh-CN': {
    ...defaultSettings,
  },
} as I18nOptions['numberFormats'];

export default numberSettings;
