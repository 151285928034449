import axios from 'axios';
import type {
  ListRespNoPage,
  ListOfValue,
  LovType,
  Pagination,
} from '@/types/store';
import type { SimpleStringKeyOption } from '@/types/global';

export type ListOfValueParams = Partial<ListOfValue> & Partial<Pagination>;

export function getLovTypes() {
  return axios.get<ListRespNoPage<LovType>>('/list_of_values/lov_types.json');
}

export function getLovListByType(lovType: string) {
  return axios.get<ListRespNoPage<ListOfValue>>(
    `/list_of_values/${lovType}/lov_values.json`,
  );
}

export function getLovOptions(lovType: string) {
  return axios.get<ListRespNoPage<SimpleStringKeyOption>>(
    `/list_of_values/${lovType}/options.json`,
  );
}

export function getListOfValue(objId: number | string) {
  return axios.get<ListOfValue>(`/list_of_values/${objId}.json`);
}

export function upsertListOfValue(data: ListOfValue) {
  if (data.id > 0) {
    return axios.put<ListOfValue>(`/list_of_values/${data.id}.json`, {
      data,
    });
  }
  return axios.post<ListOfValue>('/list_of_values.json', { data });
}

export function toggleListOfValue(objId: number | string, disabled: boolean) {
  return axios.post<ListOfValue>(`/list_of_values/${objId}/toggle.json`, {
    disabled,
  });
}

export function getPaymentTerms() {
  return getLovOptions('PAYMENT_TERMS');
}

export function getWarrantyTerms() {
  return getLovOptions('WARRANTY_TERMS');
}

export function getShippingMethod() {
  return getLovOptions('SHIPPING_METHOD');
}
