import { createRouter, createWebHistory } from 'vue-router';
import NProgress from 'nprogress'; // progress bar
import 'nprogress/nprogress.css';

import { PageEnum } from '@/enums/pageEnum';
import { LOGIN_NAME } from '@/router/constants';
import { appRoutes } from './routes';
import {
  PAGE_404_ROUTE,
  REDIRECT_ROUTE,
  EXCEPTIONS_ROUTE,
} from './routes/base';

NProgress.configure({ showSpinner: false }); // NProgress Configuration

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'Root',
      redirect: PageEnum.BASE_HOME,
    },

    // {
    //   path: PageEnum.BASE_LOGIN,
    //   name: 'WxLoginCallback',
    //   component: () => import('@/views/login/index.vue'),
    //   meta: {
    //     requiresAuth: false,
    //     hideInMenu: true,
    //   },
    // },
    {
      path: PageEnum.BASE_LOGIN,
      name: LOGIN_NAME,
      component: () => import('@/views/login/index.vue'),
      meta: {
        requiresAuth: false,
        hideInMenu: true,
      },
    },

    ...appRoutes,

    REDIRECT_ROUTE,
    EXCEPTIONS_ROUTE,
    PAGE_404_ROUTE,
    // {
    //   path: '/:pathMatch(.*)*',
    //   name: 'notFound',
    //   component: () => import('@/views/not-found/index.vue'),
    // },
  ],
  scrollBehavior() {
    return { top: 0 };
  },
});

export default router;
