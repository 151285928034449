import { defineStore } from 'pinia';
import { getNormalizedConfigs } from '@/api/app-configs';
import type { SettingState } from './types';

const useSettingStore = defineStore('setting', {
  state: (): SettingState => ({
    exchangeRates: {
      eur: 0,
      usd: 0,
    },
    freightFactors: {
      air: 0,
      sea: 0,
      railway: 0,
    },
    packagingFactors: {
      large: 0,
      medium: 0,
      small: 0,
    },
    productPriceExtraFactors: {
      global: 0,
      generalDefault: 0,
      spareDefault: 0,
    },
    discountWhq: 0,
    rateVat: 0,
    orderBonusPermil: 0,
  }),

  getters: {},

  actions: {
    async refreshAppConfigs() {
      const { data } = await getNormalizedConfigs();
      this.$patch(data as Partial<SettingState>);
    },
  },
});

export default useSettingStore;
