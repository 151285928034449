import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "200",
  height: "200",
  class: "icon",
  viewBox: "0 0 1024 1024"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#FFA000",
      d: "M931.456 176.64H459.008l-13.312-44.032c-5.12-17.152-20.864-28.928-38.784-28.928H91.776c-22.4 0-40.576 18.176-40.576 40.576V879.36c0 22.4 18.176 40.576 40.576 40.576h839.68c22.272 0 40.576-18.176 40.576-40.576V217.088c0-22.4-18.176-40.448-40.576-40.448z"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#FFF",
      d: "M867.584 869.376H155.52c-22.4 0-40.576-18.176-40.576-40.576V279.04c0-22.4 18.176-40.576 40.576-40.576h712.192c22.4 0 40.576 18.176 40.576 40.576v549.888c0 22.272-18.176 40.448-40.704 40.448zm0 0"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#FFCA28",
      d: "M931.456 919.808H91.776c-22.4 0-40.576-18.176-40.576-40.576V355.2c0-22.4 18.176-40.576 40.576-40.576h839.68c22.4 0 40.576 18.176 40.576 40.576v524.032c0 22.4-18.304 40.576-40.576 40.576zm0 0"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#2c2c2c",
      d: "M623.488 861.824H400.512c-11.136 0-20.224-9.088-20.224-20.224 0-11.136 9.088-20.224 20.224-20.224h222.976c11.136 0 20.224 9.088 20.224 20.224 0 11.136-9.088 20.224-20.224 20.224M161.792 710.4V476.8c27.52-3.2 48-4.864 65.664-4.864 65.024 0 93.44 20.864 93.44 67.584 0 26.88-11.52 44.16-34.304 50.304v1.28c26.24 5.12 40.32 24.96 40.32 56.064 0 45.184-34.944 68.224-102.784 68.224-20.096-.128-43.136-1.792-62.336-4.992zm69.376-136.96c24.96 0 33.28-7.424 33.28-30.464 0-19.84-10.24-28.8-31.104-28.8-6.4 0-12.8.64-21.504 1.664v57.6h19.328zm39.424 70.784c0-22.4-10.24-30.72-39.04-30.72h-19.584v58.24c4.48 1.28 11.52 1.664 21.12 1.664 24.32-.128 37.504-10.368 37.504-29.184m97.536-50.304c0-81.92 32.384-121.984 97.92-121.984 67.2 0 98.816 39.424 98.816 121.984 0 82.304-31.744 121.344-98.816 121.344-65.536 0-97.92-39.68-97.92-121.344zm140.544 0c0-57.6-10.624-76.8-42.624-76.8-30.72 0-41.6 19.84-41.6 76.8 0 56.32 10.88 76.16 41.6 76.16 31.744 0 42.624-19.2 42.624-76.16m106.88-118.656h79.104l35.84 114.944 7.424 39.68h2.304l7.424-39.68 5.76-17.92 30.72-97.024h78.464v236.8h-51.2V542.08h-1.92l-10.624 40.704-5.76 17.92-31.36 99.2h-46.08l-36.864-117.12-10.88-40.704h-1.92v169.984H615.68v-236.8z",
      class: "selected",
      "data-spm-anchor-id": "a313x.search_index.0.i3.569f3a81ZKHpO9"
    }, null, -1)
  ])))
}
export default { render: render }