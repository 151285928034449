/* eslint-disable no-underscore-dangle */
// @from https://blog.csdn.net/weixin_44402343/article/details/125295569

import type { Directive, App } from 'vue';

function getInput(el) {
  let inputEle;
  if (el.tagName !== 'INPUT') {
    inputEle = el.querySelector('input, textarea');
  } else {
    inputEle = el;
  }
  return inputEle;
}
function dispatchEvent(el, type) {
  // const evt = document.createEvent('HTMLEvents');
  // evt.initEvent(type, true, true);
  const evt = new Event(type, { bubbles: true, cancelable: true });
  el.dispatchEvent(evt);
}

// 过滤前后空格的指令 v-trim
const trimDirective: Directive = {
  mounted(el) {
    const inputEle = getInput(el);
    const handler = (event) => {
      const newVal = event.target.value.trim();
      if (event.target.value !== newVal) {
        event.target.value = newVal;
        dispatchEvent(inputEle, 'input');
      }
    };
    // 回车后过滤空格(因为输入框可以回车调接口查询，所以这时候单纯的失去焦点过滤不起作用，要对回车键单独做一下处理)
    const keydown = (event) => {
      if (event.keyCode === 13) {
        const newVal = event.target.value.trim();
        if (event.target.value !== newVal) {
          event.target.value = newVal;
          dispatchEvent(inputEle, 'input');
        }
      }
    };
    el.inputEle = inputEle;
    el._blurHandler = handler;
    el._keyHandler = keydown;
    inputEle.addEventListener('blur', handler);
    inputEle.addEventListener('keydown', keydown);
  },

  unmounted(el) {
    const { inputEle } = el;
    inputEle.removeEventListener('blur', el._blurHandler);
    inputEle.removeEventListener('keydown', el._keyHandler);
  },
};

export function setupTrimDirective(app: App) {
  app.directive('trim', trimDirective);
}

export default trimDirective;
