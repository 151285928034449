import { RouteLocationNormalized, RouteRecordRaw } from 'vue-router';
import { useUserStoreWithOut } from '@/store';
import { isArray, isEmpty } from '@/utils/is';

export default function usePermission() {
  const userStore = useUserStoreWithOut();
  return {
    // Rules:
    //   - Current user is admin, granted
    //   - route with false requiresAuth, granted
    //   - route with no permissions required, not granted
    //   - route with permissions included "*", granted
    //   - If users got at least one of permission required, granted
    accessRouter(route: RouteLocationNormalized | RouteRecordRaw) {
      return (
        !route.meta?.requiresAuth || this.hasPermission(route.meta?.permissions)
      );
    },

    routeWhenNoPermission() {
      return { name: 'NoPermissionException' };
    },

    // 检查当前用户是否有指定权限，如果传入的是数组，则有一个满足即可
    // 用户权限采用<key:string, string[]>的格式，key为资源名称，string[]为该资源内用户需要的权限列表，以数组组织
    // 待检查的权限配置在route.meta内，单个权限为字符串格式，以`resource_name.permission_name`的格式组织
    hasPermission(value?: string | string[]): boolean {
      if (!userStore?.permissions) {
        return false;
      }
      // 管理员，拥有所有权限
      // TODO: 临时关闭管理员权限，管理员权限还是需要通过服务端来控制，管理员本来就应该取得所有的权限，如果没有，就说明后端送往前段的权限数据有缺失
      // if (userStore.userInfo?.isAdmin) {
      //   return true;
      // }
      // 没有权限配置，且不是管理员，没有权限
      if (isEmpty(value)) {
        return false;
      }
      // 单个权限检测函数
      const hasSinglePermission = (permission: string): boolean => {
        if (permission === '*') {
          return true;
        }
        if (!permission) {
          return false;
        }
        const [resource, name] = permission.split('.');
        const permissions = userStore.permissions[resource];
        return permissions?.includes(name);
      };
      // 数组权限检测
      if (isArray(value)) {
        // 权限内包含有*，所有人都可以访问
        if ((value as string[]).includes('*')) {
          return true;
        }
        return (value as string[]).some(hasSinglePermission);
      }
      // dan个权限检测
      return hasSinglePermission(value as string);
    },
  };
}
