import { PageEnum } from '@/enums/pageEnum';
import { DEFAULT_LAYOUT } from '../base';

export default {
  path: PageEnum.BASE_HOME,
  name: 'Dashboard',
  component: DEFAULT_LAYOUT,
  meta: {
    locale: 'menu.dashboard.entry',
    requiresAuth: true,
    permissions: ['*'],
    icon: 'icon-dashboard',
    order: 0,
    extractIfOnlyChild: true,
  },
  children: [
    {
      name: 'HomeDashboardEntry',
      path: '',
      redirect: { name: 'HomeDashboard' },
      meta: {
        requiresAuth: true,
        hideInMenu: true,
        permissions: ['*'],
      },
    },
    {
      path: 'home',
      name: 'HomeDashboard',
      component: () => import('@/views/dashboard/index.vue'),
      meta: {
        locale: 'menu.dashboard.home',
        icon: 'icon-dashboard',
        requiresAuth: true,
        permissions: ['*'],
      },
    },
  ],
};
