import { h } from 'vue';
import { DEFAULT_LAYOUT } from '../base';
import IconProject from '~icons/mdi-light/forum';

export default {
  path: '/projects',
  name: 'Project',
  component: DEFAULT_LAYOUT,
  meta: {
    locale: 'menu.project.indexTitle',
    requiresAuth: true,
    icon: () => h(IconProject),
    order: 20,
    permissions: ['*'],
    extractIfOnlyChild: true,
  },
  children: [
    {
      path: '',
      name: 'ProjectsList',
      component: () => import('@/views/projects/index.vue'),
      meta: {
        locale: 'menu.project.indexTitle',
        icon: () => h(IconProject),
        requiresAuth: true,
        permissions: ['*'],
        hideInMenu: false,
      },
    },
    {
      path: ':projectId',
      name: 'ProjectDetail',
      component: () => import('@/views/projects/detail.vue'),
      meta: {
        locale: 'menu.project.listTitle',
        requiresAuth: true,
        permissions: ['*'],
        hideInMenu: true,
        activeMenu: 'ProjectsList',
      },
    },
  ],
};
