import { App } from 'vue';
import { setupPermissionDirective } from './permission';
import { setupLoadingDirective } from './loading';
import { setupTrimDirective } from './trim';
import { setupNospaceDirective } from './nospace';

export default {
  install(Vue: App) {
    setupPermissionDirective(Vue);
    setupLoadingDirective(Vue);
    setupTrimDirective(Vue);
    setupNospaceDirective(Vue);
  },
};
