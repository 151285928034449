import { DEFAULT_LAYOUT } from '../base';

export default {
  path: '/contracts',
  name: 'Contract',
  component: DEFAULT_LAYOUT,
  meta: {
    locale: 'menu.contract.indexTitle',
    requiresAuth: true,
    icon: 'icon-bookmark',
    order: 30,
    permissions: ['*'],
    extractIfOnlyChild: true,
  },
  children: [
    {
      name: 'ContractListEntry',
      path: '',
      redirect: { name: 'ContractsList' },
      meta: {
        requiresAuth: true,
        permissions: ['*'],
        hideInMenu: true,
      },
    },

    {
      path: 'list',
      name: 'ContractsList',
      component: () => import('@/views/contracts/index.vue'),
      meta: {
        locale: 'menu.contract.indexTitle',
        requiresAuth: true,
        permissions: ['*'],
        hideInMenu: false,
        icon: 'icon-bookmark',
      },
    },
  ],
};
