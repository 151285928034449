import axios from 'axios';
import type {
  ListResp,
  Brand,
  Pagination,
  ListRespNoPage,
} from '@/types/store';
import type { SimpleOption } from '@/types/global';

export type BrandParams = Partial<Brand> & Partial<Pagination>;

export function getBrands(params?: BrandParams) {
  return axios.get<ListResp<Brand>>('/brands.json', { params });
}

export function getBrand(brandId: number | string) {
  return axios.get<Brand>(`/brands/${brandId}.json`);
}

export function upsertBrand(data: Brand) {
  if (data.id > 0) {
    return axios.put<Brand>(`/brands/${data.id}.json`, { data });
  }
  return axios.post<Brand>('/brands.json', { data });
}

export function toggleBrand(brandId: number | string, disabled: boolean) {
  return axios.post<Brand>(`/brands/${brandId}/toggle.json`, { disabled });
}

export function getBrandOptions() {
  return axios.get<ListRespNoPage<SimpleOption>>('/brands/options.json');
}
