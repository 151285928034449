import { App, DirectiveBinding, Directive } from 'vue';
import usePermission from '@/hooks/permission';

function checkPermission(el: HTMLElement, binding: DirectiveBinding) {
  const { value } = binding;
  const permission = usePermission();
  if (Array.isArray(value)) {
    if (!permission.hasPermission(value) && el.parentNode) {
      el.parentNode.removeChild(el);
    }
  } else {
    throw new Error(
      `need roles! Like v-permission="['account.create','account.view_all']"`,
    );
  }
}

const authDirective: Directive = {
  mounted(el: HTMLElement, binding: DirectiveBinding) {
    checkPermission(el, binding);
  },
  updated(el: HTMLElement, binding: DirectiveBinding) {
    checkPermission(el, binding);
  },
};

export function setupPermissionDirective(app: App) {
  app.directive('permission', authDirective);
}

export default authDirective;
