<template>
  <a-input-number
    v-if="type === 'number'"
    :style="{ width: '80px' }"
    size="small"
    :default-value="defaultValue as number"
    @change="handleChange"
  />
  <a-radio-group
    v-else-if="type === 'radio'"
    size="small"
    :default-value="defaultValue"
    @change="handleChange"
  >
    <a-radio
      v-for="(option, $index) in radioOptions"
      :key="$index"
      :value="option.value"
    >
      {{ option.label }}
    </a-radio>
  </a-radio-group>
  <a-switch
    v-else
    :default-checked="defaultValue as boolean"
    size="small"
    checked-color="rgb(var(--primary-6))"
    unchecked-color="var(--color-fill-4)"
    @change="handleChange"
  />
</template>

<script lang="ts" setup>
  const props = defineProps({
    type: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    defaultValue: {
      type: [String, Boolean, Number],
      default: '',
    },
    radioOptions: {
      type: Array as PropType<{ label: string; value: string | boolean }[]>,
      default: () => [],
    },
  });
  const emit = defineEmits(['inputChange']);
  const handleChange = (value: unknown) => {
    emit('inputChange', {
      value,
      key: props.name,
    });
  };
</script>
