import axios from 'axios';
import type {
  ListResp,
  DrawingCategory,
  Pagination,
  ListRespNoPage,
} from '@/types/store';
import type { SimpleStringKeyOption } from '@/types/global';

export type DrawingCategoryParams = Partial<DrawingCategory> &
  Partial<Pagination>;

export function getDrawingCategories(params?: DrawingCategoryParams) {
  return axios.get<ListResp<DrawingCategory>>('/drawing_categories.json', {
    params,
  });
}

export function getDrawingCategory(drawingCategoryId: number | string) {
  return axios.get<DrawingCategory>(
    `/drawing_categories/${drawingCategoryId}.json`,
  );
}

export function upsertDrawingCategory(data: DrawingCategory) {
  if (data.id > 0) {
    return axios.put<DrawingCategory>(`/drawing_categories/${data.id}.json`, {
      data,
    });
  }
  return axios.post<DrawingCategory>('/drawing_categories.json', { data });
}

export function delDrawingCategory(drawingCategoryId: number | string) {
  return axios.delete<DrawingCategory>(
    `/drawing_categories/${drawingCategoryId}.json`,
  );
}

export function getDrawingCategoryOptions() {
  return axios.get<ListRespNoPage<SimpleStringKeyOption>>(
    '/drawing_categories/options.json',
  );
}
