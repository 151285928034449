import * as Sentry from '@sentry/vue';
import { consola } from 'consola/browser';
import settings from '@/config/settings.json';

export function logError(...args: any) {
  if (settings.sentryEnabled) {
    Sentry.captureException(args);
  }
  consola.error(args);
}

export function logMessage(...args: any) {
  if (settings.sentryEnabled) {
    Sentry.captureMessage(args);
  }
  consola.info(args);
}

export function logDebug(...args: any): void {
  consola.info(args);
}
