import { DEFAULT_LAYOUT } from '../base';

export default {
  path: '/products',
  name: 'ProductManagement',
  component: DEFAULT_LAYOUT,
  meta: {
    locale: 'menu.product.indexTitle',
    requiresAuth: true,
    icon: 'icon-storage',
    order: 110,
    permissions: [
      'system.admin',
      'product.manage_tech',
      'product.manage_finance',
    ],
  },
  children: [
    {
      name: 'ProductManagementEntry',
      path: '',
      redirect: { name: 'ProductInfoManagement' },
      meta: {
        requiresAuth: true,
        permissions: [
          'system.admin',
          'product.manage_tech',
          'product.manage_finance',
        ],
        hideInMenu: true,
      },
    },
    {
      path: '/products',
      name: 'ProductInfoManagement',
      component: () =>
        import('@/views/product-management/products-info-management.vue'),
      meta: {
        locale: 'menu.system.productManagement',
        requiresAuth: true,
        permissions: ['product.manage_tech'],
      },
    },
    {
      path: '/products_cost',
      name: 'ProductCostManagement',
      component: () =>
        import('@/views/product-management/products-cost-management.vue'),
      meta: {
        locale: 'menu.system.productCostManagement',
        requiresAuth: true,
        permissions: ['product.manage_finance'],
      },
    },

    {
      path: '/product_templates',
      name: 'ProductTemplateManagement',
      component: () =>
        import('@/views/product-management/product-templates-index.vue'),
      meta: {
        locale: 'menu.product.productTemplateManagement',
        requiresAuth: true,
        permissions: ['product.manage_tech'],
      },
    },

    // {
    //   path: '/product_main_categories/:mainCategoryId/sub_categories',
    //   name: 'ProductSubCategoryManagement',
    //   component: () =>
    //     import('@/views/product-management/product-sub-categories-index.vue'),
    //   meta: {
    //     locale: 'menu.system.productSubCategoriesManage',
    //     requiresAuth: true,
    //     permissions: ['system.admin'],
    //     hideInMenu: true,
    //     activeMenu: 'ProductMainCategoryManagement',
    //   },
    // },
    // {
    //   path: '/product_main_categories',
    //   name: 'ProductMainCategoryManagement',
    //   component: () =>
    //     import('@/views/product-management/product-main-categories-index.vue'),
    //   meta: {
    //     locale: 'menu.system.productMainCategoriesManage',
    //     requiresAuth: true,
    //     permissions: ['system.admin'],
    //   },
    // },

    // {
    //   path: '/brands',
    //   name: 'BrandManagement',
    //   component: () => import('@/views/system/brand-management/index.vue'),
    //   meta: {
    //     locale: 'menu.system.brandManagement',
    //     requiresAuth: true,
    //     permissions: ['system.admin'],
    //   },
    // },

    {
      path: '/product-custom-configs',
      name: 'ProductCustomConfigManagement',
      component: () =>
        import('@/views/product-management/product-custom-config/index.vue'),
      meta: {
        locale: 'menu.system.productCustomConfigManagement',
        requiresAuth: true,
        permissions: ['system.admin', 'product.manage_tech'],
      },
    },
  ],
};
