import router from '@/router';
import { PageEnum } from '@/enums/pageEnum';

const logout = (query = {}) => {
  router.push({
    path: PageEnum.BASE_LOGIN,
    query: {
      from: 'logout',
      ...query,
    },
  });
};

export { logout };
