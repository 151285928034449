import { createApp } from 'vue';
import ArcoVue, { Icon } from '@arco-design/web-vue';
import ArcoVueIcon from '@arco-design/web-vue/es/icon';
import { registerAllModules } from 'handsontable/registry';

import PrimeVue from 'primevue/config';
import Aura from '@primevue/themes/aura';
import { updatePrimaryPalette } from '@primevue/themes';
import Ripple from 'primevue/ripple';
import FloatingVue from 'floating-vue';
import ActionCableVue from 'actioncable-vue';

import * as Sentry from '@sentry/vue';

// import debug from '@/utils/env';
import globalComponents from '@/components';
import { smartDatetime } from '@/utils/date-utils';
import createRouteGuard from '@/router/guard';
import { registerGlobalComponents } from '@/utils/register-global-components';
import settings from '@/config/settings.json';
import router from './router';
import { setupStore } from './store';
import i18n from './locale';
import directive from './directive';
// import './mock';
import App from './App.vue';
import '@/api/interceptor';

import 'handsontable/dist/handsontable.full.min.css';
import 'animate.css';
// Styles are imported via arco-plugin. See config/plugin/arcoStyleImport.ts in the directory for details
// 样式通过 arco-plugin 插件导入。详见目录文件 config/plugin/arcoStyleImport.ts
// https://arco.design/docs/designlab/use-theme-package
// lguan_20230627: 还是只能全量引入，否则后期导入的样式会覆盖自定义样式
import '@arco-design/web-vue/dist/arco.css';
// 如果arco的样式在tailwind之前，可能会，会导致部分arco的样式失效
// https://github.com/arco-design/arco-design-vue/issues/759
import '@/assets/style/tailwind.css';
import '@/assets/style/global.less';
import '@/assets/style/tiptap.less';

// const VueScrollTo = require('vue-scrollto');

import 'floating-vue/dist/style.css';

const app = createApp(App);

// Global provider to be inject
app.provide('rowGutter', [10, 10]);

app.use(ArcoVue, {});
app.use(ArcoVueIcon);
// app.use(VueScrollTo);

// 配置 store
setupStore(app);

// Sentry 初始化
if (settings.sentryEnabled) {
  Sentry.init({
    enabled: import.meta.env.PROD,
    app,
    dsn: 'https://9d84c7032464377699458262a4cde57c@o196322.ingest.us.sentry.io/4507953014964224',
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

app.use(router);
createRouteGuard(router);

app.use(i18n);
app.use(globalComponents);
app.use(directive);

// Setup some global filters which can be used in template easily
app.config.globalProperties.$filters = {
  smartDatetime,
};

const IconFont = Icon.addFromIconFontCn({
  src: '/web/iconfont.js',
});
app.component('IconFont', IconFont);

app.use(PrimeVue, {
  ripple: true,
  // Default theme configuration
  theme: {
    preset: Aura,
    options: {
      prefix: 'p',
      darkModeSelector: '.primevue-theme-dark',
      // cssLayer: { name: 'primevue' },
      cssLayer: false,
    },
  },
});
updatePrimaryPalette({
  50: '#f0f9ff',
  100: '#e0f2fe',
  200: '#bae6fd',
  300: '#7dd3fc',
  400: '#38bdf8',
  500: '#0ea5e9',
  600: '#0284c7',
  700: '#0369a1',
  800: '#075985',
  900: '#0c4a6e',
  950: '#082f49',
});
app.directive('ripple', Ripple);

app.use(FloatingVue);

// register Handsontable's modules
registerAllModules();

// Register global async components
registerGlobalComponents(app);

const actionCableVueOptions = {
  debug: true,
  debugLevel: 'error',
  connectionUrl: '/cable',
  connectImmediately: true,
};
app.use(ActionCableVue, actionCableVueOptions);

app.mount('#app');
