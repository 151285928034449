import { DEFAULT_LAYOUT } from '../base';

export default {
  path: '/system',
  name: 'System',
  component: DEFAULT_LAYOUT,
  meta: {
    locale: 'menu.system.admin',
    requiresAuth: true,
    permissions: ['system.admin'],
    icon: 'icon-tool',
    order: 999,
  },
  children: [
    {
      path: 'bulletin',
      name: 'BulletinManagement',
      component: () => import('@/views/bulletin/index.vue'),
      meta: {
        locale: 'menu.system.bulletinManagement',
        requiresAuth: true,
        permissions: ['system.admin'],
      },
    },

    {
      path: 'app_configs',
      name: 'AppConfigManagement',
      component: () => import('@/views/system/app-config/index.vue'),
      meta: {
        locale: 'menu.system.appConfigManagement',
        requiresAuth: true,
        permissions: ['system.admin'],
      },
    },

    {
      path: 'list-of-value',
      name: 'ListOfValueManagement',
      component: () => import('@/views/list-of-value/index.vue'),
      meta: {
        locale: 'menu.system.lovManagement',
        requiresAuth: true,
        permissions: ['system.admin'],
      },
    },

    {
      path: 'brands',
      name: 'BrandManagement',
      component: () => import('@/views/system/brand-management/index.vue'),
      meta: {
        locale: 'menu.system.brandManagement',
        requiresAuth: true,
        permissions: ['system.admin'],
      },
    },

    {
      path: 'product_main_categories/:mainCategoryId/sub_categories',
      name: 'ProductSubCategoryManagement',
      component: () =>
        import('@/views/product-management/product-sub-categories-index.vue'),
      meta: {
        locale: 'menu.system.productSubCategoriesManage',
        requiresAuth: true,
        permissions: ['system.admin'],
        hideInMenu: true,
        activeMenu: 'ProductMainCategoryManagement',
      },
    },
    {
      path: 'product_main_categories',
      name: 'ProductMainCategoryManagement',
      component: () =>
        import('@/views/product-management/product-main-categories-index.vue'),
      meta: {
        locale: 'menu.system.productMainCategoriesManage',
        requiresAuth: true,
        permissions: ['system.admin'],
      },
    },

    {
      path: 'cache_management',
      name: 'CacheManagement',
      component: () => import('@/views/system/cache-management/index.vue'),
      meta: {
        locale: 'menu.system.cacheManagement',
        requiresAuth: true,
        permissions: ['system.admin'],
      },
    },

    {
      path: 'users_management',
      name: 'UserManagement',
      component: () => import('@/views/user/index.vue'),
      meta: {
        locale: 'menu.system.usersManagement',
        requiresAuth: true,
        permissions: ['system.admin'],
      },
    },

    {
      path: 'audit_logs',
      name: 'AuditLogManagement',
      component: () => import('@/views/audit-log/index.vue'),
      meta: {
        locale: 'menu.system.auditLogs',
        requiresAuth: true,
        permissions: ['system.admin'],
      },
    },
  ],
};
