import axios from 'axios';
import type {
  ListResp,
  ProductCustomConfig,
  Pagination,
  ListRespNoPage,
} from '@/types/store';
import type { SimpleStringKeyOption } from '@/types/global';

export type ProductCustomConfigParams = Partial<ProductCustomConfig> &
  Partial<Pagination>;

export function getProductCustomConfigs(params?: ProductCustomConfigParams) {
  return axios.get<ListResp<ProductCustomConfig>>(
    '/product_custom_configs.json',
    { params },
  );
}

export function getProductCustomConfig(productCustomConfigId: number | string) {
  return axios.get<ProductCustomConfig>(
    `/product_custom_configs/${productCustomConfigId}.json`,
  );
}

export function upsertProductCustomConfig(data: ProductCustomConfig) {
  if (data.id > 0) {
    return axios.put<ProductCustomConfig>(
      `/product_custom_configs/${data.id}.json`,
      { data },
    );
  }
  return axios.post<ProductCustomConfig>('/product_custom_configs.json', {
    data,
  });
}

export function getProductCustomConfigOptions() {
  return axios.get<ListRespNoPage<SimpleStringKeyOption>>(
    '/product_custom_configs/options.json',
  );
}
