import { h } from 'vue';
import IconBom from '@/assets/images/logos/bom.svg?component';
import IconBomList from '@/assets/images/logos/bom-list.svg?component';
import { DEFAULT_LAYOUT } from '../base';
import IconSuppliers from '~icons/fa6-solid/industry';
import IconDrawing from '~icons/mdi/file-cad';
import IconDrawingType from '~icons/hugeicons/drawing-compass';
import IconDrawingProjectGroup from '~icons/uit/object-ungroup';
import IconDrawingEntry from '~icons/carbon/cad';
import IconIssueTrackers from '~icons/fluent-mdl2/trackers';

export default {
  path: '/drawings',
  name: 'Drawing',
  component: DEFAULT_LAYOUT,
  meta: {
    locale: 'menu.drawing.indexTitle',
    requiresAuth: true,
    icon: () => h(IconDrawingEntry),
    order: 40,
    permissions: ['drawing.show', 'drawing_project.show'],
  },
  children: [
    {
      name: 'DrawingEntry',
      path: '',
      redirect: { name: 'DrawingProjectsList' },
      meta: {
        requiresAuth: true,
        permissions: ['drawing_project.show'],
        hideInMenu: true,
      },
    },

    {
      path: 'projects',
      name: 'DrawingProjectsList',
      component: () => import('@/views/drawing-project-group/index.vue'),
      meta: {
        locale: 'menu.drawing.drawingProjectGroups',
        requiresAuth: true,
        permissions: ['drawing_project.show'],
        hideInMenu: false,
        icon: () => h(IconDrawingProjectGroup),
      },
    },
    {
      path: 'projects/show/:id',
      props: true,
      component: () => import('@/views/drawing-project-group/show.vue'),
      name: 'DrawingProjectGroupDetailEntry',
      meta: {
        requiresAuth: true,
        permissions: ['drawing_project.show'],
        hideInMenu: true,
        activeMenu: 'DrawingProjectsList',
      },
      children: [
        {
          path: '',
          component: () => import('@/views/drawing-project-group/show.vue'),
          props: true,
          name: 'DrawingProjectGroupDetail',
          meta: {
            requiresAuth: true,
            permissions: ['drawing_project.show'],
            hideInMenu: true,
            activeMenu: 'DrawingProjectsList',
          },
        },
        {
          path: 'drawing/:drawingId',
          component: () =>
            import('@/views/drawing-project/components/DrawingProjectShow.vue'),
          props: true,
          name: 'DrawingFromDrawingProjectGroup',
          meta: {
            requiresAuth: true,
            permissions: ['drawing_project.show'],
            hideInMenu: true,
            activeMenu: 'DrawingProjectsList',
          },
        },
      ],
    },

    {
      path: 'list',
      name: 'DrawingsList',
      component: () => import('@/views/drawings/drawings-list.vue'),
      meta: {
        locale: 'menu.drawing.drawingsLib',
        requiresAuth: true,
        permissions: ['drawing.show'],
        hideInMenu: false,
        icon: () => h(IconDrawing),
      },
    },
    {
      path: 'show/:drawingId',
      name: 'DrawingDetail',
      component: () => import('@/views/drawings/drawing-detail.vue'),
      meta: {
        locale: 'menu.drawing.drawingsLib',
        requiresAuth: true,
        permissions: ['drawing.show'],
        hideInMenu: true,
        activeMenu: 'DrawingsList',
      },
    },

    {
      path: '/drawing_categories',
      name: 'DrawingCategorieIndex',
      component: () => import('@/views/drawing-categories/index.vue'),
      meta: {
        locale: 'menu.drawing.drawingCategories',
        requiresAuth: true,
        permissions: ['drawing.manage'],
        icon: () => h(IconDrawingType),
      },
    },

    {
      path: '/suppliers',
      name: 'SuppliersIndex',
      component: () => import('@/views/supplier/index.vue'),
      meta: {
        locale: 'menu.drawing.suppliersLib',
        requiresAuth: true,
        permissions: ['supplier.manage'],
        icon: () => h(IconSuppliers),
      },
    },

    {
      path: '/production_issues',
      name: 'ProductionIssuesIndex',
      component: () => import('@/views/production-issue/index.vue'),
      meta: {
        locale: 'menu.productionIssue.productionIssuesList',
        requiresAuth: true,
        permissions: ['production_issue.use'],
        icon: () => h(IconIssueTrackers),
      },
    },

    {
      path: 'bom-packs',
      name: 'BomPacksList',
      component: () => import('@/views/bom-pack/index.vue'),
      meta: {
        locale: 'menu.bom.bomPacksList',
        requiresAuth: true,
        permissions: ['bom_pack.use'],
        hideInMenu: false,
        icon: () => h(IconBom, { width: 18, height: 18 }),
      },
    },
    {
      path: 'bom-packs/:bomPackId',
      name: 'BomPackDetail',
      component: () => import('@/views/bom-pack/detail.vue'),
      meta: {
        locale: 'menu.bom.bomPacksDetail',
        requiresAuth: true,
        permissions: ['bom_pack.use'],
        hideInMenu: true,
        activeMenu: 'BomPacksList',
      },
    },

    {
      path: 'bom-lists',
      name: 'BomListsList',
      component: () => import('@/views/bom-list/index.vue'),
      meta: {
        locale: 'menu.bom.bomListsList',
        requiresAuth: true,
        permissions: ['bom_list.use'],
        hideInMenu: false,
        icon: () => h(IconBomList, { width: 18, height: 18 }),
      },
    },
    {
      path: 'bom-lists/:bomListId',
      name: 'BomListDetail',
      component: () => import('@/views/bom-list/detail.vue'),
      meta: {
        locale: 'menu.bom.bomListsDetail',
        requiresAuth: true,
        permissions: ['bom_list.use'],
        hideInMenu: true,
        activeMenu: 'BomListsList',
      },
    },
  ],
};
