import { h } from 'vue';
import { DEFAULT_LAYOUT } from '../base';
import IconWhiteboard from '~icons/mdi/whiteboard';

export default {
  path: '/boards',
  name: 'Board',
  component: DEFAULT_LAYOUT,
  meta: {
    locale: 'menu.board.indexTitle',
    requiresAuth: true,
    icon: () => h(IconWhiteboard),
    order: 43,
    permissions: ['board.use'],
    extractIfOnlyChild: true,
  },
  children: [
    {
      name: 'BoardListEntry',
      path: '',
      redirect: { name: 'BoardsList' },
      meta: {
        requiresAuth: true,
        permissions: ['board.use'],
        hideInMenu: true,
      },
    },

    {
      path: 'list',
      name: 'BoardsList',
      component: () => import('@/views/board/index.vue'),
      meta: {
        icon: () => h(IconWhiteboard),
        locale: 'menu.board.indexTitle',
        requiresAuth: true,
        permissions: ['board.use'],
        hideInMenu: false,
      },
    },
    {
      path: ':boardId',
      name: 'BoardDetail',
      component: () => import('@/views/board/detail.vue'),
      meta: {
        locale: 'menu.board.indexTitle',
        requiresAuth: true,
        permissions: ['board.use'],
        hideInMenu: true,
        activeMenu: 'BoardsList',
      },
    },
  ],
};
