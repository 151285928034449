import axios from 'axios';
import type { User, Lead, HotlineTicket } from '@/types/store';

export type TagsResult = {
  tags?: string[];
  users?: string[];
  keywords?: string[];
};

export function getTags(prefix: string) {
  return axios.get<TagsResult>('/tags.json', {
    params: { prefix },
    method: 'get',
  });
}

export function getProductTags() {
  return axios.get<TagsResult>('/tags/product_tags.json', {
    params: {},
    method: 'get',
  });
}

export function getClipTags() {
  return axios.get<TagsResult>('/tags/clip_tags.json', {
    params: {},
    method: 'get',
  });
}

export function getClientTags() {
  return axios.get<TagsResult>('/tags/client_tags.json');
}

export function getPermittedClientTags() {
  return axios.get<TagsResult>('/tags/permitted_client_tags.json');
}

export function getKaTagInfo(tag: string) {
  return axios.get<TagsResult>(`/tags/ka_tag_info/${tag}`);
}

export function getActionTags() {
  return axios.get<TagsResult>('/tags/action_tags.json');
}

export function getGroupTags() {
  return axios.get<TagsResult>('/tags/group_tags.json');
}

export function getLeadActionTags(modelId) {
  return axios.get<TagsResult>('/tags/lead_action_tags.json', {
    params: { modelId },
  });
}

export function setLeadActionTags(modelId, tags) {
  return axios.post('/tags/lead_action_tags.json', { modelId, tags });
}

export function getTicketGroupTags(modelId) {
  return axios.get<TagsResult>('/tags/ticket_group_tags.json', {
    params: { modelId },
  });
}

export function setTicketGroupTags(modelId, tags) {
  return axios.post('/tags/ticket_group_tags.json', {
    modelId,
    tags,
  });
}

export function getGroupTagInfo(tag) {
  return axios.get<{
    users: User[];
    tickets: HotlineTicket[];
  }>(`/tags/group_tag_info`, { params: { tag } });
}

export function getActionTagInfo(tag) {
  return axios.get<{
    users: User[];
    leads: Lead[];
  }>(`/tags/action_tag_info`, { params: { tag } });
}
