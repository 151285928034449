import { createI18n } from 'vue-i18n';
import en from './en-US.json';
import cn from './zh-CN.json';
import enEnums from './en-US/enums.json';
import cnEnums from './zh-CN/enums.json';
import cnApprovalEvents from './zh-CN/approval-events.json';
import enApprovalEvents from './en-US/approval-events.json';
import numberFormats from './number-locales';

export const LOCALE_OPTIONS = [
  { label: '中文', value: 'zh-CN' },
  { label: 'English', value: 'en-US' },
];
const defaultLocale = localStorage.getItem('cnsms-locale') || 'zh-CN';

const i18n = createI18n({
  legacy: false,
  locale: defaultLocale,
  fallbackLocale: 'zh-CN',
  allowComposition: true,
  messages: {
    'en-US': { ...en, ...enEnums, ...enApprovalEvents },
    'zh-CN': { ...cn, ...cnEnums, ...cnApprovalEvents },
  },
  numberFormats,
});

export default i18n;
