import { DEFAULT_LAYOUT } from '../base';

export default {
  path: '/manuals',
  name: 'Manual',
  component: DEFAULT_LAYOUT,
  meta: {
    locale: 'menu.manual.indexTitle',
    requiresAuth: true,
    icon: 'icon-file',
    order: 50,
    permissions: ['manual.manage'],
    extractIfOnlyChild: true,
  },
  children: [
    {
      name: 'ManualsListEntry',
      path: '',
      redirect: { name: 'ManualsList' },
      meta: {
        requiresAuth: true,
        permissions: ['manual.manage'],
        hideInMenu: true,
      },
    },

    {
      path: '/manuals',
      name: 'ManualsList',
      component: () => import('@/views/manuals/manuals-list/index.vue'),
      meta: {
        locale: 'menu.manual.indexTitle',
        requiresAuth: true,
        permissions: ['manual.manage'],
        hideInMenu: false,
        icon: 'icon-file',
      },
    },
    {
      path: '/manuals/:manualId',
      name: 'ManualDetail',
      component: () => import('@/views/manuals/manual-detail/index.vue'),
      meta: {
        locale: 'menu.manual.indexTitle',
        requiresAuth: true,
        permissions: ['manual.manage'],
        hideInMenu: true,
        activeMenu: 'ManualsList',
      },
    },
  ],
};
