import axios from 'axios';
import type { RouteRecordNormalized } from 'vue-router';
import {
  UserInfo,
  ListRespNoPage,
  User,
  Pagination,
  ListResp,
} from '@/types/store';

export interface LoginData {
  email: string;
  password: string;
}

export interface TokenResp {
  token: string;
}

export function passwordLogin(data: LoginData) {
  return axios.post<TokenResp>('/users/login', data);
}

export function getMenuList() {
  return axios.post<RouteRecordNormalized[]>('/api/user/menu');
}

// 使用企业微信回调的code来进行登录
export function wxLogin(code: string) {
  return axios.post<TokenResp>('/users/wx_login', { code });
}

// 获取当前用户信息
export function getUserInfo() {
  return axios.get<UserInfo>('/users/info');
}

export function getAllUsers(canLogin: boolean = false) {
  return axios.get<ListRespNoPage<UserInfo>>('/users/all.json', {
    params: { canLogin },
  });
}

export function userLogout() {
  return axios.post('/users/logout.json');
}

// export function getUserInfoRaw() {
//   return axios.get<UserInfo>('/users/info', { validateStatus: null });
// }

export function getUsersForModule(
  moduleName:
    | 'clip'
    | 'sales_order'
    | 'lead'
    | 'sales_for_drawing_project_group',
) {
  return axios.get<ListRespNoPage<UserInfo>>(
    `/users/list_for_module/${moduleName}.json`,
  );
}

export function createUserAction(
  actionName: string,
  modelName: string,
  modelId: number,
) {
  return axios.post<{ count: number }>(
    `/users/create_action/${actionName}/${modelName}/${modelId}.json`,
  );
}

export function destroyUserAction(
  actionName: string,
  modelName: string,
  modelId: number,
) {
  return axios.post<{ count: number }>(
    `/users/destroy_action/${actionName}/${modelName}/${modelId}.json`,
  );
}

export function getActionByUsers(
  actionName: string,
  modelName: string,
  modelId: number,
) {
  return axios.get<ListRespNoPage<User>>(
    `/users/action_by_users/${actionName}/${modelName}/${modelId}.json`,
    {},
  );
}

export type UserParams = Partial<User> & Partial<Pagination>;

export function getUsers(params?: UserParams) {
  return axios.get<ListResp<User>>('/users.json', { params });
}

export function getUser(orderId: number | string) {
  return axios.get<User>(`/users/${orderId}.json`);
}

export function upsertUser(data: User) {
  if (data.id > 0) {
    return axios.put<User>(`/users/${data.id}.json`, { data });
  }
  return axios.post<User>('/users.json', { data });
}

export function getTotpSecret() {
  return axios.get<{ totp: string }>('/users/totp.json');
}
