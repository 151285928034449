import { h } from 'vue';
import { DEFAULT_LAYOUT } from '../base';
import IconClip from '~icons/emojione-v1/books';

export default {
  path: '/clips',
  name: 'Clip',
  component: DEFAULT_LAYOUT,
  meta: {
    locale: 'menu.clip.indexTitle',
    requiresAuth: true,
    icon: () => h(IconClip),
    order: 45,
    permissions: ['clip.show'],
    extractIfOnlyChild: true,
  },
  children: [
    {
      name: 'ClipListEntry',
      path: '',
      redirect: { name: 'ClipsList' },
      meta: {
        requiresAuth: true,
        permissions: ['clip.show'],
        hideInMenu: true,
      },
    },

    {
      path: 'list',
      name: 'ClipsList',
      component: () => import('@/views/clips/index.vue'),
      meta: {
        icon: () => h(IconClip),
        locale: 'menu.clip.indexTitle',
        requiresAuth: true,
        permissions: ['clip.show'],
        hideInMenu: false,
      },
    },
    {
      path: 'tagged/:tag?',
      name: 'ClipsListWithTag',
      component: () => import('@/views/clips/index.vue'),
      meta: {
        locale: 'menu.clip.indexTitle',
        requiresAuth: true,
        permissions: ['clip.show'],
        hideInMenu: true,
        activeMenu: 'ClipsList',
      },
    },
    {
      path: 'show/:clipId',
      name: 'ClipDetail',
      component: () => import('@/views/clips/index.vue'),
      meta: {
        locale: 'menu.clip.indexTitle',
        requiresAuth: true,
        permissions: ['clip.show'],
        hideInMenu: true,
        activeMenu: 'ClipsList',
      },
    },
  ],
};
